import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaTimes } from 'react-icons/fa';
import { ParameterData } from './ParameterConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  createParameter,
  updateParameter,
} from '../../services/workflowServices';

interface ParameterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
  workflowId: string;
  initialValues?: ParameterData | null;
}

export enum WorkflowParameterType {
  Int,
  String,
  List,
  Guid,
}

const ParameterModal: React.FC<ParameterModalProps> = ({
  isOpen,
  onClose,
  workflowId,
  onEdit,
  initialValues,
}) => {
  const { t } = useTranslation();

  const formik = useFormik<ParameterData>({
    initialValues: initialValues
      ? {
          ...initialValues,
        }
      : {
          parameterName: '',
          parameterType: 0,
          workflowId,
          workflowParameterId: '',
        },
    validationSchema: Yup.object({
      parameterName: Yup.string().required(
        t('parameterNameRequired', 'Parameter Name is required')
      ),
      parameterType: Yup.string().required(
        t('parameterTypeRequired', 'Parameter Type is required')
      ),
    }),
    onSubmit: (values) => {
      if (initialValues) {
        updateAParameter(values);
      } else {
        const { workflowParameterId, ...payloadWithoutWorkflowParameterId } =
          values;
        createNewParameter(payloadWithoutWorkflowParameterId);
      }
    },
  });

  const createNewParameter = async (values: any) => {
    try {
      const parameterType = Number(values.parameterType);
      const response: any = await createParameter({ ...values, parameterType });
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        onEdit();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  const updateAParameter = async (values: any) => {
    try {
      const parameterType = Number(values.parameterType);
      const response: any = await updateParameter({ ...values, parameterType });
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        onEdit();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  if (!isOpen) return null; // If the modal is closed, return nothing

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-2xl w-full max-w-3xl relative">
        {/* Close button */}
        <button
          className="absolute top-4 right-4 text-gray-600"
          onClick={onClose}
        >
          <FaTimes size={20} />
        </button>

        <h2 className="text-2xl mb-4">{t('addParameter', 'Add Parameter')}</h2>

        {/* Divider */}
        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between mb-4">
            {/* Parameter Name Dropdown */}
            <div className="flex-1 mr-4">
              <label htmlFor="parameterName" className="block mb-2">
                {t('parameterName', 'Parameter Name')}
              </label>
              <input
                id="parameterName"
                name="parameterName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.parameterName}
                className="w-full border text-sm border-gray-300 p-2 rounded-lg"
              />
              {formik.touched.parameterName && formik.errors.parameterName ? (
                <div className="text-red-500">
                  {formik.errors.parameterName}
                </div>
              ) : null}
            </div>

            {/* Parameter Type Dropdown */}
            <div className="flex-1">
              <label htmlFor="parameterType" className="block mb-2">
                {t('parameterType', 'Parameter Type')}
              </label>
              <select
                id="parameterType"
                name="parameterType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.parameterType}
                className="block w-full h-10 text-sm text-gray-700 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              >
                <option
                  value={WorkflowParameterType.Int}
                  label={t('int', 'Int')}
                >
                  {t('int', 'Int')}
                </option>
                <option
                  value={WorkflowParameterType.String}
                  label={t('string', 'String')}
                >
                  {t('string', 'String')}
                </option>
                <option
                  value={WorkflowParameterType.List}
                  label={t('list', 'List')}
                >
                  {t('list', 'List')}
                </option>
                <option
                  value={WorkflowParameterType.Guid}
                  label={t('guid', 'Guid')}
                >
                  {t('guid', 'Guid')}
                </option>
              </select>
              {formik.touched.parameterType && formik.errors.parameterType ? (
                <div className="text-red-500">
                  {formik.errors.parameterType}
                </div>
              ) : null}
            </div>
          </div>

          {/* Divider */}
          <div className="divider my-8 !bg-[#E5E5E5]"></div>

          {/* Save Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-500 text-white px-12 h-12 py-2 rounded-lg font-semibold"
            >
              {t('saveParameters', 'Save Parameters')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ParameterModal;
