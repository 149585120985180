import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InitiatedTaskTabContent from './InitiatedTaskTabContent';
import AssignedTabContent from './AssignedTabContent';
import ProcessedTaskTabContent from './ProcessedTaskTabContent';

const ApplicationsOnMyDesk: React.FC = () => {
  const { t } = useTranslation();

  // State to manage active tab
  const [activeTab, setActiveTab] = useState<
    'Assigned' | 'Initiated Task' | 'Processed Task'
  >('Assigned');

  // Handler for switching tabs
  const handleTabClick = (
    tab: 'Assigned' | 'Initiated Task' | 'Processed Task'
  ) => {
    setActiveTab(tab);
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Title and Button */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">
          {t('applicationsOnMyDesk', 'Applications on My Desk')}
        </h1>
      </div>

      {/* Tabs Section */}
      <div className="border-b mb-4">
        <div className="flex justify-start space-x-8">
          <TabItem
            title={t('assigned', 'Assigned')}
            isActive={activeTab === 'Assigned'}
            onClick={() => handleTabClick('Assigned')}
          />
          <TabItem
            title={t('initiatedTask', 'Initiated Task')}
            isActive={activeTab === 'Initiated Task'}
            onClick={() => handleTabClick('Initiated Task')}
          />
          <TabItem
            title={t('processedTask', 'Processed Task')}
            isActive={activeTab === 'Processed Task'}
            onClick={() => handleTabClick('Processed Task')}
          />
        </div>
      </div>

      {/* Content for Each Tab */}
      <div>
        {activeTab === 'Assigned' && <AssignedTabContent />}
        {activeTab === 'Initiated Task' && <InitiatedTaskTabContent />}
        {activeTab === 'Processed Task' && <ProcessedTaskTabContent />}
      </div>
    </div>
  );
};

// Component for each tab title
interface TabItemProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const TabItem: React.FC<TabItemProps> = ({ title, isActive, onClick }) => {
  return (
    <button
      className={`pb-2 text-lg font-medium focus:outline-none ${
        isActive ? 'border-b-4 border-[#FFC726] font-bold' : 'text-gray-500'
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default ApplicationsOnMyDesk;
