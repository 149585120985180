import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';

let setGlobalLoading: ((loading: boolean) => void) | null = null; // Global setter

export type UserRole = '' | 'Admin' | 'Regulator' | 'Supplier' | 'Operator' | 'System User';

interface GlobalContextProps {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  isLoggedIn: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  supplierId: string | null;
  setSupplierId: (id: string | null) => void;
  userRole: UserRole | null;
  setUserRole: (role: UserRole | null) => void;
  login: (supplierId: string | null, role: UserRole) => void;
  logout: () => void;
}

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [supplierId, setSupplierId] = useState<string | null>(() => {
    return localStorage.getItem('supplierId');
  });
  const [userRole, setUserRole] = useState<UserRole | null>(() => {
    return localStorage.getItem('userRole') as UserRole | null;
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Update the global setter for loading
  setGlobalLoading = setIsLoading;

  const updateSupplierId = (id: string | null) => {
    setSupplierId(id);
    if (id) {
      localStorage.setItem('supplierId', id);
    } else {
      localStorage.removeItem('supplierId');
    }
  };

  const updateUserRole = (role: UserRole | null) => {
    setUserRole(role);
    if (role) {
      localStorage.setItem('userRole', role);
    } else {
      localStorage.removeItem('userRole');
    }
  };

  const login = (supplierId: string | null, role: UserRole) => {
    setIsLoggedIn(true);
    localStorage.setItem('isAuthenticated', 'true');
    if (role === '') {
      role = 'Supplier';
    }
    updateSupplierId(supplierId);
    updateUserRole(role);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isAuthenticated');
    setSupplierId(null);
    localStorage.removeItem('supplierId');
    setUserRole(null);
    localStorage.removeItem('userRole');
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(localStorage.getItem('isAuthenticated') === 'true');
      setSupplierId(localStorage.getItem('supplierId'));
      setUserRole(localStorage.getItem('userRole') as UserRole | null);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isOpen,
        setIsOpen,
        isLoggedIn,
        supplierId,
        setSupplierId: updateSupplierId,
        userRole,
        setUserRole: updateUserRole,
        login,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

// Export the global loading setter for external use
export const setGlobalLoadingState = (loading: boolean) => {
  if (setGlobalLoading) {
    setGlobalLoading(loading);
  }
};
