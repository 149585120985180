import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import KeyPersonnelModal from './AddPersonnelModal';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import Delete from '../../../shared/utils/Delete/Delete';
import CustomTable, { Column } from '../../../shared/components/CustomTable';
import {
  deletePersonnel,
  getPersonnels,
} from '../../../services/supplierService';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface EducationInformation {
  educationInformationId: string;
  schoolName: string;
  startYear: string;
  endYear: string;
  certificateAwarded: string;
  personalId: string;
  educationLevel: string;
  major: string;
}

export interface WorkExperience {
  workExperienceId: string;
  companyName: string;
  appointmentDate: string;
  disengagementDate: string;
  jobTitle: string;
  personalId: string;
  country: string;
  descriptions: string;
  isCurrentJob: boolean;
  proofOfEmployment: string;
}

export interface KeyPersonnel {
  personalId: string;
  fullName: string;
  country: string;
  address: string;
  stateOfOrigin: string;
  maritalStatus: string;
  phoneNumber: string;
  email: string;
  designation: string;
  educationInformations: EducationInformation[];
  workExperiences: WorkExperience[];
}

export const KeyPersonnelTable: React.FC<{ isEditable: boolean }> = ({
  isEditable,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPersonnel, setSelectedPersonnel] =
    useState<KeyPersonnel | null>(null);
  const [keyPersonnel, setKeyPersonnel] = useState<KeyPersonnel[]>([]);
  const { supplierId } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Items per page for pagination

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = keyPersonnel.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(keyPersonnel.length / itemsPerPage);

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId');

  const columns: Column[] = [
    {
      title: t('fullName', 'Full Name'),
      dataKey: 'fullName',
      type: 'string',
    },
    {
      title: t('maritalStatus', 'Marital Status'),
      dataKey: 'maritalStatus',
      type: 'string',
    },
    { title: t('email', 'Email'), dataKey: 'email', type: 'string' },
    {
      title: t('phoneNumber', 'Phone Number'),
      dataKey: 'phoneNumber',
      type: 'string',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: KeyPersonnel) => editPersonnel(item),
          status: !isEditable,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: KeyPersonnel) => editPersonnel(item),
          status: isEditable,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: KeyPersonnel) => deleteModal(item),
          status: isEditable,
        },
      ],
    },
  ];

  const editPersonnel = (personnel: KeyPersonnel) => {
    setSelectedPersonnel(personnel);
    setIsModalOpen(true);
  };

  const deleteModal = (personnel: KeyPersonnel) => {
    setSelectedPersonnel(personnel);
    setIsDeleteModalOpen(true);
  };

  const deleteKeyPersonnel = async () => {
    if (!selectedPersonnel) return;
    const response = await deletePersonnel(selectedPersonnel.personalId);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
      fetchKeyPersonnelData();
    }
    setIsDeleteModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedPersonnel(null); // Clear for adding new personnel
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPersonnel(null);
    fetchKeyPersonnelData();
  };

  const fetchKeyPersonnelData = async () => {
    const fetchedId = isSupplier ? supplierId : companyId;
    if (!fetchedId) return;
    const response = await getPersonnels(fetchedId, {
      pageNumber: 1,
      pageSize: 10,
    });
    if (response) setKeyPersonnel(response.data);
  };

  useEffect(() => {
    fetchKeyPersonnelData();
  }, [supplierId]);

  return (
    <div className="p-4">
      <CustomTable
        data={currentItems}
        columns={columns}
        pageCount={pageCount}
        showPagination={true}
        showSearch={true}
        showAddButton={isEditable} // Conditionally show the Add button based on isEditable
        addButtonText={t('add', '+ Add')}
        showTitle={false}
        onAddButtonClick={openModal} // Open modal for adding a new contact
      />

      {isModalOpen && (
        <KeyPersonnelModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={selectedPersonnel} // Set form values for editing or empty for new personnel
          isEditable={isEditable}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t(
            'deleteWarning',
            'This action is permanent, all data would be lost.'
          )}
          onDelete={deleteKeyPersonnel}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};
