import React from 'react';
import { CiSearch } from 'react-icons/ci'; // Ensure you have the CiSearch icon imported

interface SearchBoxProps {
  placeholder?: string;
  onSearch: (value: string) => void; // Callback function for handling search input
}

const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder = 'Search...',
  onSearch,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <div className="relative flex items-center">
      <CiSearch className="absolute left-3 text-gray-500" size={16} />
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleInputChange}
        className="pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:border-gray-400"
      />
    </div>
  );
};

export default SearchBox;
