import { Configuration, LogLevel } from '@azure/msal-browser';
import { toast } from 'react-toastify';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            // toast.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            // toast.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            // toast.info(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            // toast.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
