import React, { ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { WorkExperience } from './KeyPersonels';

interface employmentProps {
  isEditable: boolean;
  parentFormik: any;
}

const EmploymentForm: React.FC<employmentProps> = ({
  parentFormik,
  isEditable,
}) => {
  const { t } = useTranslation();

  const handleAddEmployment = () => {
    parentFormik.setFieldValue('workExperiences', [
      ...parentFormik.values.workExperiences,
      {
        workExperienceId: '',
        companyName: '',
        appointmentDate: '',
        disengagementDate: '',
        personalId: '',
        jobTitle: '',
        country: '',
        descriptions: '',
        isCurrentJob: false,
        proofOfEmployment: '',
      },
    ]);
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.currentTarget.files;
    if (!files) return;
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      const base64StringWithoutData = base64String.split(',')[1];

      parentFormik.setFieldValue(
        `workExperiences[${index}].proofOfEmployment`,
        base64StringWithoutData
      );
    };

    reader.onerror = () => {
      toast.error(t('errorReadingFile', 'Error reading file'));
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveEmployment = (index: number) => {
    const updatedworkExperiences = parentFormik.values.workExperiences.filter(
      (_: any, i: number) => i !== index
    );
    parentFormik.setFieldValue('workExperiences', updatedworkExperiences);
  };

  const handleViewFile = (fileBase64: string) => {
    const byteCharacters = atob(fileBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          {t('workExperience', 'Work Experience')}
        </h2>
        {isEditable && ( // Remove Add button when not editable
          <button
            type="button"
            onClick={handleAddEmployment}
            className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-lg"
          >
            {t('add', '+ Add')}
          </button>
        )}
      </div>

      {/* Divider */}
      <div className="divider my-6 !bg-[#E5E5E5]"></div>

      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {parentFormik.values.workExperiences?.map(
          (employment: WorkExperience, index: number) => (
            <div key={index} className="mb-8 border p-4 rounded-lg">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold">
                  {t('workExperienceNumber', 'Work Experience')} {index + 1}
                </h2>
                <div className="flex justify-end">
                  {index !== 0 &&
                    isEditable && ( // Only show delete icon if editable
                      <img
                        onClick={() => handleRemoveEmployment(index)}
                        className="h-7 w-7 cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/assets/images/trash.svg`}
                        alt={t('delete', 'delete')}
                      />
                    )}
                </div>
              </div>

              {/* Divider */}
              <div className="divider my-6 !bg-[#E5E5E5]"></div>

              <div className="grid grid-cols-3 gap-4 mb-8">
                <div>
                  <label>{t('jobTitle', 'Job Title')}*</label>
                  <input
                    type="text"
                    name={`workExperiences[${index}].jobTitle`}
                    disabled={!isEditable}
                    value={employment.jobTitle}
                    onChange={parentFormik.handleChange}
                    placeholder={t('jobTitlePlaceholder', 'What is your role?')}
                    className="w-full text-sm border h-10 rounded-lg"
                  />
                  {parentFormik.touched.workExperiences?.[index]?.jobTitle &&
                    parentFormik.errors.workExperiences?.[index]?.jobTitle && (
                      <span className="text-red-500">
                        {parentFormik.errors.workExperiences?.[index]?.jobTitle}
                      </span>
                    )}
                </div>
                <div>
                  <label>{t('companyName', 'Company Name')}*</label>
                  <input
                    type="text"
                    name={`workExperiences[${index}].companyName`}
                    disabled={!isEditable}
                    value={employment.companyName}
                    onChange={parentFormik.handleChange}
                    placeholder={t(
                      'companyNamePlaceholder',
                      'Enter the name of the company'
                    )}
                    className="w-full text-sm border h-10 rounded-lg"
                  />
                  {parentFormik.touched.workExperiences?.[index]?.companyName &&
                    parentFormik.errors.workExperiences?.[index]
                      ?.companyName && (
                      <span className="text-red-500">
                        {
                          parentFormik.errors.workExperiences?.[index]
                            ?.companyName
                        }
                      </span>
                    )}
                </div>
                <div>
                  <label>{t('country', 'Country')}*</label>
                  <input
                    type="text"
                    name={`workExperiences[${index}].country`}
                    disabled={!isEditable}
                    value={employment.country}
                    onChange={parentFormik.handleChange}
                    placeholder={t('countryPlaceholder', 'Location of the Job')}
                    className="w-full text-sm border h-10 rounded-lg"
                  />
                  {parentFormik.touched.workExperiences?.[index]?.country &&
                    parentFormik.errors.workExperiences?.[index]?.country && (
                      <span className="text-red-500">
                        {parentFormik.errors.workExperiences?.[index]?.country}
                      </span>
                    )}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-8">
                <div className="col-span-3">
                  <label>{t('description', 'Description')}*</label>
                  <textarea
                    rows={5}
                    name={`workExperiences[${index}].descriptions`}
                    disabled={!isEditable}
                    value={employment.descriptions}
                    onChange={parentFormik.handleChange}
                    className="w-full text-sm border rounded-lg p-2"
                  />
                  {parentFormik.touched.workExperiences?.[index]
                    ?.descriptions &&
                    parentFormik.errors.workExperiences?.[index]
                      ?.descriptions && (
                      <span className="text-red-500">
                        {
                          parentFormik.errors.workExperiences?.[index]
                            ?.descriptions
                        }
                      </span>
                    )}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-8">
                <div className="col-span-1">
                  <label>{t('from', 'From')}*</label>
                  <input
                    type="date"
                    name={`workExperiences[${index}].appointmentDate`}
                    disabled={!isEditable}
                    value={employment.appointmentDate}
                    onChange={parentFormik.handleChange}
                    className="w-full text-sm border rounded-lg h-10"
                  />
                  {parentFormik.touched.workExperiences?.[index]
                    ?.appointmentDate &&
                    parentFormik.errors.workExperiences?.[index]
                      ?.appointmentDate && (
                      <span className="text-red-500">
                        {
                          parentFormik.errors.workExperiences?.[index]
                            ?.appointmentDate
                        }
                      </span>
                    )}
                </div>
                <div className="col-span-1">
                  <label>{t('to', 'To')}*</label>
                  <input
                    type="date"
                    name={`workExperiences[${index}].disengagementDate`}
                    value={employment.disengagementDate}
                    onChange={parentFormik.handleChange}
                    className="w-full text-sm border rounded-lg h-10"
                    disabled={!isEditable || employment.isCurrentJob}
                  />
                  {parentFormik.touched.workExperiences?.[index]
                    ?.disengagementDate &&
                    parentFormik.errors.workExperiences?.[index]
                      ?.disengagementDate && (
                      <span className="text-red-500">
                        {
                          parentFormik.errors.workExperiences?.[index]
                            ?.disengagementDate
                        }
                      </span>
                    )}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-8">
                <div className="col-span-1 flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={`workExperiences[${index}].isCurrentJob`}
                    checked={employment.isCurrentJob}
                    onChange={parentFormik.handleChange}
                    className="w-6 h-16 text-sm border rounded-lg"
                    disabled={!isEditable}
                  />
                  <span className="text-sm">
                    {t('currentlyWorkHere', 'I currently work here')}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                {/* Upload Proof of Employment */}
                <div className="col-span-3 verification-upload">
                  <label className="text-lg font-medium text-gray-500">
                    {t('proofOfEmployment', 'Proof of Employment')}
                  </label>
                  <div className="flex items-center gap-2">
                    {isEditable ? (
                      <>
                        <input
                          className="border p-0.5 rounded-lg w-full h-[32px]"
                          type="file"
                          id={`workExperiences[${index}].proofOfEmployment`}
                          name={`workExperiences[${index}].proofOfEmployment`}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            const element = document.getElementById(
                              `workExperiences[${index}].proofOfEmployment`
                            );
                            if (element) {
                              element.click();
                            }
                          }}
                          className="bg-[#20b841] w-[108px] text-white h-[32px] text-lg flex justify-center items-center font-semibold rounded-lg"
                        >
                          {t('upload', 'Upload')}
                        </button>
                      </>
                    ) : (
                      <div className=" w-full flex justify-end">
                        <button
                          type="button"
                          className="text-[#20b841] flex text-lg font-semibold underline"
                          onClick={() => {
                            // Handle view logic for the file here
                            // console.log(`Viewing document for employment ${index}`);
                          }}
                        >
                          {t('view', 'View')}
                        </button>
                      </div>
                    )}
                  </div>
                  <span className="text-[#667085] text-lg">
                    {t(
                      'uploadInstructions',
                      'Please upload .jpeg, .jpg, .png, or .pdf, size less than 100KB'
                    )}
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EmploymentForm;
