import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { Endpoint } from '../../endpoint/endpoint';
import {
  getIndustries,
  getSupplierTypes,
  getTags,
} from '../../services/procurementService';

interface Industry {
  industryId: string;
  industryName: string;
}

interface SupplierType {
  lookupId: string;
  lookupName: string;
  lookupValue: string;
}

interface Supplier {
  companyId: string;
  businessName: string;
}

interface ServiceInformationProps {
  formData: {
    projectTitle: string;
    industryId: string;
    isBiddingProject: boolean;
    supplierTypeToBid: string;
    tags: { tagName: string }[];
    projectDescription: string;
    prerequisites: string;
    projectDocuments: any[];
    referenceToWork: string;
    additionalInformation: string;
    biddingStartDate: Date;
    biddingEndDate: Date;
    bidRequirement: string;
    status?: number;
  };
  handleChange: (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | {
          target: {
            name: string;
            value: string | boolean;
            checked?: boolean;
            type?: string;
          };
        }
  ) => void;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ServiceInformation: React.FC<ServiceInformationProps> = ({
  formData,
  handleChange,
  handleFileUpload,
}) => {
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [supplierTypes, setSupplierTypes] = useState<SupplierType[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSupplierDropdown, setShowSupplierDropdown] = useState(false);

  useEffect(() => {
    fetchIndustries();
    fetchSupplierTypes();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      fetchSuppliers();
    } else {
      setShowSupplierDropdown(false);
    }
  }, [searchTerm]);

  const fetchIndustries = async () => {
    try {
      const response = await getIndustries();
      if (response.isSuccess) {
        setIndustries(response.data);
      }
    } catch {
      // console.error('Error fetching industries:', error);
    }
  };

  const fetchSupplierTypes = async () => {
    try {
      const response = await getSupplierTypes();
      if (response.isSuccess) {
        setSupplierTypes(response.data);
      }
    } catch {
      // console.error('Error fetching supplier types:', error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const response = await getTags();
      const filteredSuppliers = response.data.filter((supplier: Supplier) =>
        supplier.businessName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSuppliers(filteredSuppliers);
      setShowSupplierDropdown(true);
    } catch {
      // console.error('Error fetching suppliers:', error);
    }
  };

  const handleSupplierSelect = (businessName: string) => {
    const existingTags = formData.tags.map((tag) => tag.tagName);
    if (!existingTags.includes(businessName)) {
      const newEvent = {
        target: {
          name: 'tags',
          value: [...existingTags, businessName].join(', '),
        },
      };
      handleChange(newEvent);
    }
    setSearchTerm('');
    setShowSupplierDropdown(false);
  };

  const handleTagSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({
      target: {
        name: e.target.name,
        value: e.target.checked,
        type: 'checkbox',
        checked: e.target.checked,
      },
    });
  };

  const formatDate = (date: string | number | Date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const isDisabled2 = formData.status === 0;

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6">Provide service details</h2>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="projectTitle"
        >
          Title of Procurement*
        </label>
        <input
          type="text"
          id="projectTitle"
          name="projectTitle"
          value={formData.projectTitle}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Title of procurement plan. Easy to understand"
          disabled={isDisabled2}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="industryId"
        >
          Industry*
        </label>
        <select
          id="industryId"
          name="industryId"
          value={formData.industryId}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border py-5 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          disabled={isDisabled2}
        >
          <option value="">Select</option>
          {industries.map((industry) => (
            <option key={industry.industryId} value={industry.industryId}>
              {industry.industryName}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="isBiddingProject"
          name="isBiddingProject"
          checked={formData.isBiddingProject}
          onChange={handleCheckboxChange}
          className="h-4 w-4 text-yellow-400 focus:ring-yellow-500 checked:bg-yellow-400 border-gray-300 rounded"
          disabled={isDisabled2}
        />
        <label
          htmlFor="isBiddingProject"
          className="ml-2 block text-sm text-gray-900"
        >
          I want suppliers to go through the bidding process on this project?
        </label>
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="supplierTypeToBid"
        >
          Which type of Supplier should bid *
        </label>
        <select
          id="supplierTypeToBid"
          name="supplierTypeToBid"
          value={formData.supplierTypeToBid}
          onChange={handleChange}
          className="mt-1 block w-full py-5 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          disabled={isDisabled2}
        >
          <option value="">Select</option>
          {supplierTypes.map((type) => (
            <option key={type.lookupId} value={type.lookupValue}>
              {type.lookupName}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full max-w-full flex space-x-4">
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Bidding start date <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type="date"
              id="biddingStartDate"
              name="biddingStartDate"
              className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm 
                        focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50
                        text-gray-700 placeholder-gray-400"
              value={formatDate(formData.biddingStartDate)}
              onChange={handleChange}
              placeholder="MM/DD/YYYY"
            />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Bidding end date <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type="date"
              id="biddingEndDate"
              name="biddingEndDate"
              className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm 
                        focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50
                        text-gray-700 placeholder-gray-400"
              value={formatDate(formData.biddingEndDate)}
              onChange={handleChange}
              placeholder="MM/DD/YYYY"
            />
          </div>
        </div>
      </div>

      <div className="mb-4 relative">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="tags"
        >
          Tags (Search and select suppliers)
        </label>
        <input
          type="text"
          id="tags"
          value={searchTerm}
          onChange={handleTagSearch}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Search suppliers..."
          disabled={isDisabled2}
        />
        {showSupplierDropdown && suppliers.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            {suppliers.map((supplier) => (
              <div
                key={supplier.companyId}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSupplierSelect(supplier.businessName)}
              >
                {supplier.businessName}
              </div>
            ))}
          </div>
        )}
        {formData.tags.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {formData.tags.map((tag, index) => (
              <span
                key={index}
                className="bg-gray-200 px-2 py-1 rounded-md text-sm"
              >
                {tag.tagName}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="projectDescription"
        >
          Description
        </label>
        <textarea
          id="projectDescription"
          name="projectDescription"
          value={formData.projectDescription}
          onChange={handleChange}
          rows={4}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Describe the project"
          disabled={isDisabled2}
        ></textarea>
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="bidRequirement"
        >
          Bid Requirement
        </label>
        <textarea
          id="bidRequirement"
          name="bidRequirement"
          value={formData.bidRequirement}
          onChange={handleChange}
          rows={4}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter bidding criteria"
          disabled={isDisabled2}
        ></textarea>
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="prerequisites"
        >
          Prerequisites or Submission requirements
        </label>
        <textarea
          id="prerequisites"
          name="prerequisites"
          value={formData.prerequisites}
          onChange={handleChange}
          rows={4}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="What are the supplier requirements you are looking for?"
          disabled={isDisabled2}
        ></textarea>
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="referenceToWork"
        >
          Reference to any work that have been done
        </label>
        <textarea
          id="referenceToWork"
          name="referenceToWork"
          value={formData.referenceToWork}
          onChange={handleChange}
          rows={4}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Paste links"
          disabled={isDisabled2}
        ></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Sample of Outcome or Supporting documents
        </label>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={handleFileUpload}
                  multiple
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
        {formData.projectDocuments.length > 0 && (
          <div className="mt-2">
            <h4 className="text-sm font-medium text-gray-700">
              Uploaded files:
            </h4>
            <ul className="mt-1 space-y-1">
              {formData.projectDocuments.map((file: File, index: number) => (
                <li key={index} className="text-sm text-gray-500">
                  {file.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="additionalInformation"
        >
          Additional Information
        </label>
        <textarea
          id="additionalInformation"
          name="additionalInformation"
          value={formData.additionalInformation}
          onChange={handleChange}
          rows={4}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Note / Comment"
          disabled={isDisabled2}
        ></textarea>
      </div>
    </div>
  );
};

export default ServiceInformation;
