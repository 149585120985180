import { lookup } from 'dns';
import { create } from 'domain';
import { get } from 'http';

const baseUrl = process.env.REACT_APP_BASE_URL;
export const Endpoint = {
  account: {
    login: `${baseUrl}/account/login`,
    register: `${baseUrl}/account/register`,
    confirmEmail: `${baseUrl}/account/confirmEmail`,
    resendOtp: `${baseUrl}/account/resend/otp`,
    googleAuth: `${baseUrl}/account/google`,
    externalOAuth: `${baseUrl}/account/externallogin?provider=`,
    refreshToken: `${baseUrl}/account/token/refresh`,
    forgotPassword: `${baseUrl}/account/forgotPassword`,
    resetPassword: `${baseUrl}/account/resetPassword`,
    role: {
      create: `${baseUrl}/account/role/create`,
      get: `${baseUrl}/account/roles`,
      getOne: (roleId: string) => `${baseUrl}/account/role/${roleId}`,
      update: `${baseUrl}/account/role/edit`,
      delete: `${baseUrl}/account/role/delete`,
    },

    user: {
      get: `${baseUrl}/account/employee/list`,
      getStaff: `${baseUrl}/account/ads/users`,
      create: `${baseUrl}/account/employee/create`,
      update: `${baseUrl}/account/employee/edit`,
      delete: `${baseUrl}/account/employee/remove`,
      getOne: (userId: string) => `${baseUrl}/account/employee/${userId}`,
    },
    menu: {
      create: `${baseUrl}/account/menu/create`,
      getAll: `${baseUrl}/account/menu/all`,
      update: `${baseUrl}/account/menu/edit`,
      delete: `${baseUrl}/account/menu/delete`,
      get: (menuId: string) => `${baseUrl}/account/menu/${menuId}`,
      mymenu: `${baseUrl}/account/menu/mymenu`,
    },
    permission: {
      get: `${baseUrl}/account/permissions`,
    },
    lookup: {
      getType: (lookupType: string) => `${baseUrl}/lookup/${lookupType}`,
      create: `${baseUrl}/lookup/create`,
      update: `${baseUrl}/lookup/edit`,
      delete: `${baseUrl}/lookup/remove`,
    },
  },
  suppliers: {
    getAll: `${baseUrl}/supplier/list`,
    getSubsidiaries: `${baseUrl}/supplier/subsidiaries`,
    getOne: (id: string) => `${baseUrl}/supplier/suppliers?supplierId=${id}`,
    create: `${baseUrl}/supplier/create`,
    update: `${baseUrl}/supplier/edit`,
    delete: `${baseUrl}/supplier/remove`,
    setStatus: `${baseUrl}/supplier/setstatus`,
    progress: (supplierId: string) => `${baseUrl}/supplier/progress?supplierId=${supplierId}`,
    contact: {
      create: `${baseUrl}/supplier/contact/create`,
      update: `${baseUrl}/supplier/contact/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/contacts/${supplierId}`,
      delete: `${baseUrl}/supplier/contact/remove`,
    },
    personnel: {
      create: `${baseUrl}/supplier/personnel/create`,
      update: `${baseUrl}/supplier/personnel/edit`,
      get: (supplierId: string) =>
        `${baseUrl}/supplier/personnel/personnels/${supplierId}`,
      delete: `${baseUrl}/supplier/personnel/remove`,
    },
    industry: {
      create: `${baseUrl}/supplier/industry/create`,
      update: `${baseUrl}/supplier/industry/edit`,
      get: `${baseUrl}/supplier/industries`,
      delete: `${baseUrl}/supplier/industry/delete`,
    },
    shareholder: {
      create: `${baseUrl}/supplier/shareholder/create`,
      update: `${baseUrl}/supplier/shareholder/edit`,
      get: (supplierId: string) =>
        `${baseUrl}/supplier/shareholders/${supplierId}`,
      delete: `${baseUrl}/supplier/shareholder/remove`,
    },
    project: {
      create: `${baseUrl}/supplier/project/create`,
      update: `${baseUrl}/supplier/project/edit`,
      get: `${baseUrl}/supplier/projects`,
      delete: `${baseUrl}/supplier/project/delete`,
    },
    award: {
      create: `${baseUrl}/supplier/award/create`,
      update: `${baseUrl}/supplier/award/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/awards/${supplierId}`,
      delete: `${baseUrl}/supplier/award/delete`,
    },
    additionalInfo: {
      create: `${baseUrl}/supplier/additional/create`,
      update: `${baseUrl}/supplier/additional/edit`,
      get: (supplierId: string) =>
        `${baseUrl}/supplier/additional/${supplierId}`,
      delete: `${baseUrl}/supplier/additional/delete`,
    },
  },
  workflow: {
    get: `${baseUrl}/workflow/list`,
    create: `${baseUrl}/workflow/create`,
    update: `${baseUrl}/workflow/edit`,
    delete: `${baseUrl}/workflow/delete`,
    getTypes: `${baseUrl}/workflow/types`,
    initiate: `${baseUrl}/workflow/initiate`,
    processTask: `${baseUrl}/workflow/processTask`,
    command: {
      create: `${baseUrl}/workflow/command/create`,
      update: `${baseUrl}/workflow/command/edit`,
      get: `${baseUrl}/workflow/commands`,
      delete: `${baseUrl}/workflow/command/remove`,
    },
    assignedTask: {
      get: `${baseUrl}/workflow/assignedTask`,
    },
    taskHistory: {
      getOne: (requestId: string) =>  `${baseUrl}/workflow/history/${requestId}`,
    },
    process: {
      create: `${baseUrl}/workflow/process/create`,
      update: `${baseUrl}/workflow/process/edit`,
      get: `${baseUrl}/workflow/process/list`,
      delete: `${baseUrl}/workflow/process/remove`,
      getOne: (id: string) => `${baseUrl}/workflow/process/${id}`,
    },
    parameter: {
      create: `${baseUrl}/workflow/parameter/create`,
      update: `${baseUrl}/workflow/parameter/edit`,
      get: `${baseUrl}/workflow/parameter/list`,
      delete: `${baseUrl}/workflow/parameter/remove`,
      getTypes: (id: number) => `${baseUrl}/workflow/workflowParameters/${id}`,
    },
    step: {
      create: `${baseUrl}/workflow/step/create`,
      update: `${baseUrl}/workflow/step/edit`,
      get: `${baseUrl}/workflow/steps`,
      delete: `${baseUrl}/workflow/steps/remove`,
      getOne: (id: string) => `${baseUrl}/workflow/step/${id}`,
    },
  },

  procurement: {
    create: `${baseUrl}/procurementProjects/create`,
    update: `${baseUrl}/procurementProjects/edit`,
    get: `${baseUrl}/procurementProjects/list`,
    getOne: `${baseUrl}/procurementProjects/procurementproject`,
    getIndustries: `${baseUrl}/supplier/industries`,
    getSupplierTypes: `${baseUrl}/lookup/SupplierType`,
    getSuppliers: `${baseUrl}/supplier/list?pageNumber=1&pageSize=1000000`,
    unpublishProcurement: `${baseUrl}/procurementProjects/unpublish`,
  },
};
