import React, { useState, useEffect } from 'react';
import { Search, MoreHorizontal } from 'lucide-react';
import axiosInstance from '../../axiosInstance';
import ProcurementProjectDetails from './ProcurementProjectDetails';
import CreateProcurementForm from './CreateProcurementForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { Endpoint } from '../../endpoint/endpoint';
import { getAllProcurements } from '../../services/procurementService';

interface ProcurementProject {
  procurementProjectId: string;
  projectTitle: string;
  projectDescription: string;
  prerequisites: string;
  status: number;
  lastUpdated: string;
  additionalInformation: string;
  tags: { tagName: string }[];
  industryId: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  projectGoodsAndServices: any[];
  projectDocuments: any[];
  referenceToWork: string;
  assignPointsToReferenceToWork: boolean;
  approvedDate: Date | null;
  biddingStartDate: Date;
  biddingEndDate: Date;
  bidRequirement: string;
}

interface ApiResponse {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  data: ProcurementProject[];
}

const ProcurementCard = ({
  project,
  onClick,
}: {
  project: ProcurementProject;
  onClick: () => void;
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const getStatusLabel = (status: number) => {
    switch (status) {
      case 0:
        return { label: 'Published', className: 'bg-green-100 text-green-700' };
      case 1:
        return { label: 'Unpublished', className: 'bg-gray-100 text-gray-700' };
      case 2:
        return { label: 'Denied', className: 'bg-red-100 text-red-700' };
      case 3:
        return { label: 'Unpublished', className: 'bg-gray-100 text-gray-700' };
      default:
        return { label: 'Unknown', className: 'bg-gray-100 text-gray-700' };
    }
  };

  const status = getStatusLabel(project.status);

  return (
    <div
      className="bg-white rounded-lg border border-gray-200 p-4 cursor-pointer shadow-sm"
      onClick={onClick}
    >
      <div className="flex justify-between items-center mb-2">
        <span
          className={`text-sm font-semibold px-3 py-1 rounded-full ${status.className}`}
        >
          {status.label}
        </span>
        <MoreHorizontal className="text-gray-600" size={20} />
      </div>
      <h3 className="font-semibold text-lg text-gray-800 mb-2">
        {project.projectTitle}
      </h3>
      <p className="text-base text-gray-700 mb-2 py-4 line-clamp-2">
        {project.projectDescription}
      </p>
      <p className="text-sm pt-4 pb-0 text-green-600">
        {formatDate(project.lastUpdated)}
      </p>
    </div>
  );
};

const ProcurementHomepage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Tender Opportunities');
  const [activeStatus, setActiveStatus] = useState('Approved');
  const [procurements, setProcurements] = useState<ProcurementProject[]>([]);
  const [filteredProcurements, setFilteredProcurements] = useState<
    ProcurementProject[]
  >([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProject, setSelectedProject] =
    useState<ProcurementProject | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const statusMap = {
    Approved: 0,
    Reviewing: 1,
    Denied: 2,
    Drafts: 3,
  };

  const fetchProcurements = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response: ApiResponse = await getAllProcurements(
        statusMap,
        activeStatus
      );
      setProcurements(response?.data);
      setFilteredProcurements(response?.data);
    } catch {
      setError('Failed to fetch procurements. Please try again later.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProcurements();
  }, [activeStatus]);

  useEffect(() => {
    if (location.state && location.state.refresh) {
      fetchProcurements();
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state]);

  // Filter procurements based on search term
  useEffect(() => {
    const filtered = procurements.filter((project) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        project.projectTitle.toLowerCase().includes(searchLower) ||
        project.projectDescription.toLowerCase().includes(searchLower)
      );
    });
    setFilteredProcurements(filtered);
  }, [searchTerm, procurements]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateButtonClick = () => {
    setShowCreateForm(true);
  };

  const handleCloseCreateForm = () => {
    setShowCreateForm(false);
    fetchProcurements();
  };

  return (
    <div className="flex">
      <div className="flex-1 bg-gray-100 min-h-screen">
        <main className="p-8">
          {showCreateForm ? (
            <CreateProcurementForm onClose={handleCloseCreateForm} />
          ) : (
            <>
              <header className="mb-8">
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="text-3xl font-bold mb-1 text-gray-900">
                      Electronic Procurement
                    </h1>
                    <p className="text-base text-gray-700">
                      Create tender opportunities and get qualified suppliers
                    </p>
                  </div>
                  {!showCreateForm && (
                    <button
                      className="bg-primary text-black px-9 py-3 rounded-md text-base font-semibold"
                      onClick={handleCreateButtonClick}
                    >
                      + Create
                    </button>
                  )}
                </div>
              </header>
              <div className="flex space-x-8 mb-6 border-b border-gray-200">
                {['Tender Opportunities', 'Proposals'].map((tab) => (
                  <button
                    key={tab}
                    className={`px-4 py-3 font-semibold text-base text-gray-900 ${activeTab === tab ? 'border-b-2 border-primary' : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              <div className="relative mb-6">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-4/5 px-4 py-3 pl-12 bg-white border border-gray-300 rounded-md text-base text-gray-800"
                />
                <Search
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                  size={20}
                />
              </div>
              <div className="flex space-x-10 mb-6">
                {Object.keys(statusMap).map((status) => (
                  <button
                    key={status}
                    className={`px-6 py-3 rounded-full text-base font-semibold ${activeStatus === status ? 'bg-green-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                    onClick={() => setActiveStatus(status)}
                  >
                    {status}
                  </button>
                ))}
              </div>
              {isLoading ? (
                <p className="text-lg text-gray-700">Loading...</p>
              ) : error ? (
                <p className="text-lg text-red-600">{error}</p>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-4/5 gap-6">
                  {filteredProcurements.map((project) => (
                    <ProcurementCard
                      key={project.procurementProjectId}
                      project={project}
                      onClick={() => setSelectedProject(project)}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </main>
      </div>
      {selectedProject && (
        <ProcurementProjectDetails
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
};

export default ProcurementHomepage;
