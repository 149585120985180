import React from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
  previousLabel?: string;
  nextLabel?: string;
  breakLabel?: string;
  containerClassName?: string;
  activeClassName?: string;
  previousClassName?: string;
  nextClassName?: string;
  disabledClassName?: string;
  pageClassName?: string;
  pageLinkClassName?: string;
  previousLinkClassName?: string;
  nextLinkClassName?: string;
  activeLinkClassName?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  onPageChange,
  marginPagesDisplayed = 2,
  pageRangeDisplayed = 5,
  previousLabel = '< Previous',
  nextLabel = 'Next >',
  breakLabel = '...',
  containerClassName = 'pagination items-center flex space-x-2',
  activeClassName = 'bg-black rounded-lg text-white',
  previousClassName = 'page-item',
  nextClassName = 'page-item',
  disabledClassName = 'disabled',
  pageClassName = 'page-item px-3 py-1 border rounded-lg hover:bg-gray-200',
  pageLinkClassName = 'page-link',
  previousLinkClassName = 'page-link',
  nextLinkClassName = 'page-link',
  activeLinkClassName = 'active-link',
}) => {
  return (
    <div className="flex justify-center items-center mt-4">
      <ReactPaginate
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        breakLabel={breakLabel}
        pageCount={pageCount}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={onPageChange}
        containerClassName={containerClassName}
        activeClassName={activeClassName}
        previousClassName={previousClassName}
        nextClassName={nextClassName}
        disabledClassName={disabledClassName}
        pageClassName={pageClassName}
        pageLinkClassName={pageLinkClassName}
        previousLinkClassName={previousLinkClassName}
        nextLinkClassName={nextLinkClassName}
        activeLinkClassName={activeLinkClassName}
      />
    </div>
  );
};

export default Pagination;
