import React from 'react';
import './ForgetPassword.css';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import { ForgotPasswordValidationSchema } from '../ValidationSchema';
import AppButton from '../../../shared/components/AppButton/AppButton';
import { useNavigate } from 'react-router-dom';
import { ForgotPassword } from '../../../services/AccountServices';
import { toast } from 'react-toastify';

const RecoverPasswordCard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: ForgotPasswordValidationSchema,
    onSubmit: async (values: { email: string }) => {
      const response = await ForgotPassword(values);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        navigate('/otp', {
          state: { email: values.email, isForgetPassword: true },
        });
      } else {
        toast.error(response?.errorMessage);
      }
    },
  });
  return (
    <Modal
      isOpen={true}
      style={{
        content: {
          width: '700px',
          height: '400px',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          overflow: 'hidden',
          border: 'none',
        },
      }}
    >
      <center className="recover-password-card flex  flex-col py-10">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/forgetPassword/forgetPassword.svg`}
          alt={t('recoverPassword', 'Recover Password')}
        />
        <h2 className=" flex justify-start">
          {t('recoverPassword', 'Recover Password')}
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-start">
            <label htmlFor="email">{t('emailAddress', 'Email Address')}</label>
          </div>

          <input
            type="email"
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder={t('nameEmail', 'name@emailOrUsername.com')}
          />
          {formik.touched.email && formik.errors.email && (
            <span className="flex justify-start mb-2 text-red-500 text-sm">
              {formik.errors.email}
            </span>
          )}
          <AppButton
            className="w-full bg-primary text-black font-semibold text-lg py-5 rounded-md"
            title={t('reset password', 'Reset Password')}
            onClick={formik.handleSubmit}
            // onClick={() => navigate('./password-reset')}
          />
        </form>
      </center>
    </Modal>
  );
};

export default RecoverPasswordCard;
