// ApplicationModule.tsx
import React from 'react';
import Module from '../Module';
import { modulesData } from '../index';
import { useTranslation } from 'react-i18next';

const ApplicationModule: React.FC = () => {
  const { t } = useTranslation();
  const title = `${t('accessible_modules', 'Accessible Modules')}`;
  const desc = `${t('select_a_module', 'Select a module')}`;

  return <Module title={title} desc={desc} modulesData={modulesData} />;

  // const [filteredModulesData, setFilteredModulesData] = useState(modulesData);

  // useEffect(() => {
  //   const fetchMenuData = async () => {
  //     try {
  //       const response = await myMenu();
  //       if (response.isSuccess) {
  //         const accessibleMenuIds = response.data.map((menu: any) => menu.menuId);
  //         const filteredData = modulesData.filter((module: any) =>
  //           accessibleMenuIds.includes(module.menuId)
  //         );
  //         setFilteredModulesData(filteredData);
  //       } else {
  //         console.error(response.errorMessage);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching menu data:', error);
  //     }
  //   };

  //   fetchMenuData();
  // }, []);

  // return <Module title={title} desc={desc} modulesData={filteredModulesData} />;
};

export default ApplicationModule;
