import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import { useTranslation } from 'react-i18next'; // Import useTranslation
import {
  createWorkflow,
  getCommands,
  getWorkflowTypes,
  updateWorkflow,
} from '../../services/workflowServices';
import { toast } from 'react-toastify';
import { WorkflowItem } from './workflowConfig';
import { CommandData } from '../commandConfig/CommandConfig';

interface ModalProps {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  onCreateSuccess: () => void;
  workflow?: WorkflowItem | null;
}

interface WorkflowTypes {
  [key: string]: number;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  typeOfWorkflow: Yup.number()
    .required('Type of Workflow is required')
    .integer('Type of Workflow must be an integer'),
  workflowName: Yup.string()
    .required('Workflow Name is required')
    .min(3, 'Workflow Name should be at least 3 characters long'),
  descriptions: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be at least 10 characters long'),
});

const WorkFlowModal: React.FC<ModalProps> = ({
  isModalOpen,
  setModalOpen,
  onCreateSuccess,
  workflow,
}) => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowTypes>({});
  const [commandData, setCommandData] = useState<CommandData[]>([]);
  const formik = useFormik({
    initialValues: {
      typeOfWorkflow: workflow ? workflow.typeOfWorkflow : 0,
      workflowName: workflow ? workflow.workflowName : '',
      descriptions: workflow ? workflow.descriptions : '',
      viewDataSource: workflow ? workflow.viewDataSource : '',
      workflowId: workflow ? workflow.workflowId : '',
    },
    validationSchema, // Attach validation schema
    onSubmit: (values) => {
      const typeOfWorkflow = Number(values.typeOfWorkflow);
      try {
        const payload = { ...values, typeOfWorkflow };
        if (workflow) {
          handleUpdateWorkflow(payload);
        } else {
          delete payload.workflowId;
          handleCreateWorkflow(payload);
        }
      } catch (error: any) {
        toast.error(error);
      }
      setModalOpen(false);
    },
  });

  const handleCreateWorkflow = async (payload: any) => {
    const response: any = await createWorkflow(payload);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      onCreateSuccess();
    } else {
      toast.error(response?.data?.message);
    }
  };

  const handleUpdateWorkflow = async (payload: any) => {
    const response: any = await updateWorkflow(payload);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      onCreateSuccess();
    } else {
      toast.error(response?.data?.message);
    }
  };

  const getWorkFlowTypes = async () => {
    try {
      const response = await getWorkflowTypes();
      setWorkflowTypes(response);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const fetchCommandList = async () => {
    try {
      const response = await getCommands({
        pageNumber: 1,
        pageSize: 100,
        workflowId: workflow?.workflowId,
      });
      if (response) {
        setCommandData(response.data);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchCommandList();
  }, [workflow?.workflowId]);

  useEffect(() => {
    getWorkFlowTypes();
  }, []);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
          <div className="bg-white w-full max-w-3xl p-8 rounded-2xl relative">
            <button
              onClick={() => setModalOpen(false)}
              className="absolute top-3 right-3 text-gray-500 text-2xl"
            >
              <FaTimes />
            </button>
            <h1 className="text-2xl font-bold">
              {t('workflowConfiguration', 'Workflow Configuration')}
            </h1>

            {/* Divider */}
            <div className="divider my-6 !bg-[#E5E5E5]"></div>

            {/* Form handled by Formik */}
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-6">
                <label
                  htmlFor="typeOfWorkflow"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('workflowType', 'Workflow Type')}
                </label>
                <select
                  id="typeOfWorkflow"
                  name="typeOfWorkflow"
                  onChange={formik.handleChange}
                  value={formik.values.typeOfWorkflow}
                  disabled={workflow ? true : false}
                  className="block w-full h-10 text-sm text-gray-700 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">
                    {t('selectWorkflowType', 'Select workflow type')}
                  </option>
                  {workflowTypes &&
                    Object.entries(workflowTypes).map(([name, value]) => (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    ))}
                </select>
                {formik.errors.typeOfWorkflow &&
                  formik.touched.typeOfWorkflow && (
                    <span className="text-red-500">
                      {formik.errors.typeOfWorkflow}
                    </span>
                  )}
              </div>
              {commandData && commandData.length > 0 && (
                <div className="mb-6">
                  <label
                    htmlFor="viewDataSource"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('datasource', 'DataSource')}
                  </label>
                  <select
                    id="viewDataSource"
                    name="viewDataSource"
                    onChange={formik.handleChange}
                    value={formik.values.viewDataSource}
                    className="block w-full h-10 text-sm text-gray-700 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">
                      {t('selectDataSource', 'Select datasource')}
                    </option>
                    {commandData &&
                      commandData.map((command) => (
                        <option value={command.workflowCommandId}>
                          {command.descriptions}
                        </option>
                      ))}
                  </select>
                  {formik.errors.viewDataSource &&
                    formik.touched.viewDataSource && (
                      <span className="text-red-500">
                        {formik.errors.viewDataSource}
                      </span>
                    )}
                </div>
              )}

              <div className="mb-6">
                <label
                  htmlFor="workflowName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('workflowName', 'Workflow Name')}
                </label>
                <input
                  type="text"
                  id="workflowName"
                  name="workflowName"
                  placeholder={t('workflowNamePlaceholder', 'Workflow Name')}
                  className={`w-full border text-sm border-gray-300 p-2 rounded-lg ${
                    formik.touched.workflowName && formik.errors.workflowName
                      ? 'border-red-500'
                      : ''
                  }`}
                  value={formik.values.workflowName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // For blur validation
                />
                {/* Show validation error */}
                {formik.touched.workflowName && formik.errors.workflowName && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.workflowName}
                  </div>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="descriptions"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('description', 'Description')}
                </label>
                <textarea
                  id="descriptions"
                  name="descriptions"
                  placeholder={t('descriptionPlaceholder', 'Enter description')}
                  className={`w-full border border-gray-300 p-2 rounded-lg ${
                    formik.touched.descriptions && formik.errors.descriptions
                      ? 'border-red-500'
                      : ''
                  }`}
                  rows={4}
                  value={formik.values.descriptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {/* Show validation error */}
                {formik.touched.descriptions && formik.errors.descriptions && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.descriptions}
                  </div>
                )}
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setModalOpen(false)}
                  className="bg-gray-300 text-black-700 px-12 h-12 py-2 rounded-lg font-semibold"
                >
                  {t('close', 'Close')}
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-12 h-12 py-2 rounded-lg font-semibold"
                >
                  {workflow ? t('update', 'Update') : t('publish', 'Publish')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkFlowModal;
