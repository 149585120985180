import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

interface BreadcrumbProps {
  paths: { label: string; path: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ paths }) => {
  return (
    <nav aria-label="breadcrumb" className="mb-4 my-5">
      <ol className="flex space-x-2 text-gray-500">
        {paths.map((crumb, index) => (
          <li key={index} className="flex items-center">
            {index !== paths.length - 1 ? (
              <>
                <Link to={crumb.path} className="text-blue-600 hover:underline">
                  {crumb.label}
                </Link>
                <span className="mx-2">
                  <FaAngleRight />
                </span>
              </>
            ) : (
              <span className="text-gray-700">{crumb.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
