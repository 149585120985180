import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SuccessCard.css';
import { FadeLoader } from 'react-spinners';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface SuccessCardProps {
  isLoading?: boolean;
  title?: string;
}

const SuccessCard: React.FC<SuccessCardProps> = ({ title }) => {
  const { t } = useTranslation();
  const [showSecondImage, setShowSecondImage] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.state?.route;
  const message = location.state?.message;

  // Show the second image after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSecondImage(true);
      setIsOpen(false);
      if (route) {
        navigate(route);
      }
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer);
  }, [[navigate, route]]);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          width: '700px',
          height: '400px',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          overflow: 'hidden',
          border: 'none',
        },
      }}
    >
      <div className="success-card border-none gap-4 ">
        <div className="flex flex-col justify-center ">
          {showSecondImage && (
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/forgetPassword/Illustration.svg`}
              alt={t('illustration', 'Illustration')}
              className=" w-[200px] h-[100px]"
            />
          )}
          {showSecondImage && (
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/forgetPassword/thumbs-up.svg`}
              alt={t('thumbsUp', 'Thumbs Up')}
              className=" w-[200px] h-[50px]"
            />
          )}
        </div>
        <div>
          <h2 className=" font-bold text-[24px] text-green-600">{title}</h2>
        </div>
        <div className=" text-sm">
          <p>{message}</p>
        </div>

        <FadeLoader color="#FFC726" />
      </div>
    </Modal>
  );
};

export default SuccessCard;
