import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  deleteContact,
  deleteSupplier,
  getContacts,
  getSubsidiaries,
} from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';
import { CompanyRegisterFormValues } from './SupplierRegistration';
import AffiliateModal from './AffilateModal';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../shared/components/CustomTable';
import { useLocation } from 'react-router-dom';

export const AffiliateTable: React.FC<{ isEditable: boolean }> = ({
  isEditable,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [affiliateList, setAffiliateList] = useState<
    CompanyRegisterFormValues[]
  >([]);
  const [selectedAffiliate, setSelectedAffiliate] =
    useState<CompanyRegisterFormValues | null>(null);
  const { supplierId } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(25); // Items per page for pagination
  const [pageCount, setPageCount] = useState(1);

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId');

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const columns: Column[] = [
    {
      title: t('businessName', 'Business Name'),
      dataKey: 'businessName',
      type: 'string',
    },
    // { title: t('country', 'Country'), dataKey: 'country', type: 'string' },
    {
      title: t('commercialRegNo', 'Commercial Reg. No'),
      dataKey: 'registrationNumber',
      type: 'string',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: CompanyRegisterFormValues) => editModal(item),
          status: !isEditable,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: CompanyRegisterFormValues) => editModal(item),
          status: isEditable,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: CompanyRegisterFormValues) => deleteModal(item),
          status: isEditable,
        },
      ],
    },
  ];

  const fetchData = async () => {
    const fetchedId = isSupplier ? supplierId : companyId;
    if (!fetchedId) return;
    const response = await getSubsidiaries(fetchedId, pageRequest);
    if (response) {
      setAffiliateList(response.data);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  const deleteSelectedAffiliate = async () => {
    if (!selectedAffiliate) return;
    const { companyId } = selectedAffiliate;
    if (!companyId) return;
    const response: any = await deleteSupplier(companyId);
    if (response) {
      toast.success(response?.message);
      setIsDeleteModalOpen(false);
      fetchData();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedAffiliate(null);
  };
  const editModal = (affiliate: CompanyRegisterFormValues) => {
    setIsModalOpen(true);
    setSelectedAffiliate(affiliate);
  };
  const deleteModal = (affiliate: CompanyRegisterFormValues) => {
    setSelectedAffiliate(affiliate);
    setIsDeleteModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAffiliate(null);
    fetchData();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedAffiliate(null);
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  return (
    <div className="p-4">
      <CustomTable
        data={affiliateList}
        columns={columns}
        pageCount={pageCount}
        showPagination={true}
        showSearch={true}
        showAddButton={isEditable} // Conditionally show the Add button based on isEditable
        addButtonText={t('add', '+ Add')}
        showTitle={false}
        onAddButtonClick={openModal}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />

      {isModalOpen && (
        <AffiliateModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={selectedAffiliate}
          isEditable={isEditable}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure')}
          desc={t(
            'permanentActionWarning',
            'This action is permanent, all data would be lost.'
          )}
          onDelete={deleteSelectedAffiliate}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};
