import React, { useEffect, useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { FaSort } from 'react-icons/fa'; // Importing sort icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // Importing 3-dots icon
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import WorkFlowModal from './WorkFlowModal';
import { getWorkflows } from '../../services/workflowServices';
import { workflowTypeToString } from '../../services/utilServices';
import { useNavigate } from 'react-router-dom';
import CustomTable, { Column } from '../../shared/components/CustomTable';
import { useTranslation } from 'react-i18next';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
}

export type WorkflowItem = {
  workflowId?: string;
  descriptions: string;
  viewDataSource: string;
  workflowName: string;
  typeOfWorkflow: number;
};

const WorkflowConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const [selectedAll, setSelectedAll] = useState(false);
  const [workflowData, setWorkflowData] = useState<WorkflowItem[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkflowItem | null>(
    null
  );
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(2); // Items per page for pagination
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const navigate = useNavigate();
  const pageRequest: PageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = workflowData.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(workflowData.length / itemsPerPage);

  const columns: Column[] = [
    {
      title: t('applicationType', 'Application Type'),
      dataKey: 'typeOfWorkflow',
      type: 'applicationType',
    },
    {
      title: t('numberOfStages', 'Number of Stages'),
      dataKey: 'stages',
      type: 'string',
    },
    {
      title: t('status', 'Status'),
      dataKey: 'status',
      type: 'status',
      statusColors: {
        active: 'bg-[#42BE65] text-white',
        inactive: 'bg-[#FA4D56] text-white',
        pending: 'bg-[#FFCC00] text-black',
      },
    },
    {
      title: t('createdDate', 'Created Date'),
      dataKey: 'createdDate',
      type: 'string',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (workflow: WorkflowItem) => handleEdit(workflow),
          status: true,
        },
        {
          label: t('commands', 'Commands'),
          action: (item: any) => handleCommand(item),
          status: true,
        },
        {
          label: t('parameters', 'Parameters'),
          action: (item: any) => handleParameter(item),
          status: true,
        },
        {
          label: t('steps', 'Steps'),
          action: (item: any) => handleStep(item),
          status: true,
        },
        // { label: t('delete', 'Delete'), className: "text-red-500", action: (item: any) => handleDelete(item) },
      ],
    },
  ];

  const handleEdit = (workflow: WorkflowItem) => {
    setSelectedWorkflow(workflow);
    setModalOpen(true);
  };

  const handleCommand = (item: WorkflowItem) => {
    navigate('/settings/workflow/command', {
      state: { workflowId: item.workflowId },
    });
  };

  const handleParameter = (item: WorkflowItem) => {
    navigate('/settings/workflow/parameter', {
      state: { workflowId: item.workflowId },
    });
  };

  const handleStep = (item: WorkflowItem) => {
    navigate('/settings/workflow/steps', {
      state: { workflowId: item.workflowId },
    });
  };

  const handleDelete = (item: WorkflowItem) => {
    // Implement delete logic here
  };

  const fetchData = async () => {
    const response = await getWorkflows(pageRequest);
    if (response) setWorkflowData(response.data);
  };

  const handleAddButtonClick = () => {
    setModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="p-4 flex-1">
        <CustomTable
          data={currentItems}
          columns={columns}
          pageCount={pageCount} // Pass the total page count
          showPagination={true} // Control whether to show pagination
          showSearch={true} // Control whether to show the search box
          onSearch={handleSearch} // Pass the search handler
          showAddButton={true}
          addButtonText={t('addButtonText', '+ Add')}
          title=""
          onAddButtonClick={handleAddButtonClick}
        />

        {isModalOpen && (
          <WorkFlowModal
            isModalOpen={isModalOpen}
            setModalOpen={(value) => setModalOpen(value)}
            onCreateSuccess={fetchData}
            workflow={selectedWorkflow}
          />
        )}
      </div>
    </>
  );
};

export default WorkflowConfiguration;
