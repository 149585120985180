import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FormComponent from './SupplierForm/SupplierRegistration';
import { ShareholdersTable } from './SupplierForm/ShareHolders';
import { KeyPersonnelTable } from './SupplierForm/KeyPersonels';
import { PrimaryContactTable } from './SupplierForm/PrimaryContact';
import PricingComponent from './SupplierForm/Payments';
import { ProjectAndAwardTable } from './SupplierForm/ProjectAndAward';
import { useGlobalContext } from '../../core/context/GlobalContext';
import { AffiliateTable } from './SupplierForm/Affiliate';
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewModal from '../OperatorManagement/ReviewAppModal';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import {
  getParameterTypes,
  initiateWorkflow,
} from '../../services/workflowServices';
import { use } from 'i18next';
import { toast } from 'react-toastify';
import DocumentRequestModal from '../module/operatorModule/RequestAddDoc';
import { createAdditional, setSupplierStatus } from '../../services/supplierService';
import { useTranslation } from 'react-i18next';

interface IWorkflowInitiateValues {
  entityId: string;
  entityName: string;
  parameters: Array<{ parameterName: string; parameterValue: string }>;
  selectedWorkflowType: string;
}

interface IParameterData {
  workflowParameterId: string;
  parameterName: string;
  parameterType: string;
  workflowId: string;
}

export enum OnboardingStatus {
  New,
  Processing,
  Rejected,
  Approved,
}

const SupplierManagement: React.FC = () => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { supplierId } = useGlobalContext();
  const [affiliates, setAffiliates] = useState<
    Array<{ businessName: string; country: string; commercialReg: string }>
  >([]);
  const [parameterTypes, setParameterTypes] = useState<IParameterData[]>([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isDocumentRequestModalOpen, setIsDocumentRequestModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const requestId = location.state?.requestId;
  const entityName = location.state?.entityName;
  const entityId = location.state?.entityId;
  const isReview = location.state?.isReview;
  const isTerminatingStep = location.state?.isTerminatingStep;
  const userId = localStorage.getItem('userId') || '';
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId') || '';
  const navigate = useNavigate();

  const formRef = useRef<{ submitForm: () => void }>(null);

  // Define your tab titles
  const tabTitles = [
    t('companyInformation', 'Company Information'),
    t('affiliates', 'Affiliates'),
    t('primaryContact', 'Primary Contact'),
    t('keyPersonnel', 'Key Personnel'),
    t('shareholders', 'Shareholders'),
    t('projectsAndAwards', 'Projects and Awards'),
    // t('payments', 'Payments'),
  ];

  // Calculate progress (in percentage)
  const progressPercentage = (selectedIndex / (tabTitles.length - 1)) * 100;

  // const handleNext = () => {
  //   setSelectedIndex((prevIndex) =>
  //     prevIndex < tabTitles.length - 1 ? prevIndex + 1 : 0
  //   );
  // };

  const handleNext = async() => {
    if (selectedIndex === 0 && formRef.current) {
      // Trigger form submission in SupplierRegistration
      if (isSupplier) {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      } else {
        setSelectedIndex((prevIndex) => prevIndex + 1);
      }
    } else {
      if (isSupplier && selectedIndex === tabTitles.length - 1) {
        const status = OnboardingStatus.New;
        const supplierId = localStorage.getItem('supplierId');
        if (!supplierId)  return;  
        const setStatusResponse = await setSupplierStatus(supplierId, status);
        if(setStatusResponse?.isSuccess) {
            toast.success(t('applicationSubmittedSuccessfully', 'Your application was submitted successfully'));
            setTimeout(() => {
              navigate('/supplier-application');
            }, 2000);
        }else {
          toast.error(setStatusResponse?.errorMessage);
        }
      } else if (!isSupplier && selectedIndex === tabTitles.length - 1) {
        navigate('/supplier-requests');
      } else {
        setSelectedIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const moveToNextTab = () => {
    setSelectedIndex((prevTab) => prevTab + 1); // Move to the next tab
  };

  const handlePrevious = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : tabTitles.length - 1
    );
  };

  const handleExit = () => {
    if (isSupplier) {
      navigate('/supplier-application');
    } else {
      navigate('/supplier-requests');
    }
  };

  const reviewApplication = async () => {
    if (parameterTypes.length < 1)
      return toast.error(t('noParameterTypeFound', 'No parameter type found'));
    const payload: IWorkflowInitiateValues = {
      entityId: companyId,
      entityName: 'Supplier',
      parameters: parameterTypes.map((parameterType) => {
        let parameterValue = '';
        if (parameterType.parameterName.toLowerCase().includes('baseurl')) {
          //parameterValue = `${window.location.origin}${window.location.pathname}`;
          parameterValue = window.location.origin;
        }
        if (parameterType.parameterName.toLowerCase().includes('supplierid')) {
          parameterValue = companyId;
        }
        return {
          parameterName: parameterType.parameterName,
          parameterValue,
        };
      }),

      selectedWorkflowType: 'Supplier',
    };

    const response = await initiateWorkflow(payload);
    if (response?.isSuccess) {
      const status = OnboardingStatus.Processing;
      const setStatusResponse = await setSupplierStatus(companyId, status);
      if(setStatusResponse?.isSuccess) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate('/supplier-requests');
        }, 2000);
      }else {
        toast.error(setStatusResponse?.errorMessage);
      }
      
    }else {
      toast.error(response?.errorMessage);
    }

    setIsConfirmationModalOpen(false);
  };

  const handleReview = () => {
    if (isReview) {
      return setIsReviewModalOpen(true);
    }
    return setIsConfirmationModalOpen(true);
  };

  const getParameters = async () => {
    const response = await getParameterTypes(0);

    if (response?.isSuccess) {
      setParameterTypes(response.data);
    }
  };

  const handleApproval = () => {
    setIsReviewModalOpen(true);
    navigate('/application-desk');
  };

  const requestAdditionalDocument = async (data: any) => {
    const payload = {
      reason: data?.reason,
      comment: data?.remarks,
      companyId: companyId,
    };

    const response = await createAdditional(payload);
    if (response?.isSuccess) {
      const status = OnboardingStatus.Rejected;
      const setStatusResponse = await setSupplierStatus(companyId, status);
      if(setStatusResponse?.isSuccess) {
        toast.success(response?.data?.message);
        setIsDocumentRequestModalOpen(false);
        setTimeout(() => {
          navigate('/supplier-requests');
        }, 2000);
      }else {
        toast.error(setStatusResponse?.errorMessage);
      }
      
    }
  };

  useEffect(() => {
    if (!isSupplier) {
      getParameters();
    }
  }, [isSupplier]);

  return (
    <div className="p-6 w-full">
      <div className="flex justify-between">
        <h1 className="font-bold text-3xl mb-8">
          {t('supplierManagement', 'Supplier Management')}
        </h1>
        {!isSupplier && (
          <div className="flex gap-4">
            {!isReview && (
              <button
                type="button"
                className="text-black px-12 h-12 py-6 flex items-center border border-[#E5E5E5] rounded-lg font-semibold"
                onClick={() => setIsDocumentRequestModalOpen(true)}
              >
                {t('requestAdditionalDocument', 'Request Additional Document')}
              </button>
            )}
            <button
              type="button"
              className="bg-green-500 text-white px-12 h-12 py-6 flex items-center rounded-lg font-semibold"
              onClick={() => handleReview()}
            >
              {t('reviewApplication', 'Review Application')}
            </button>
          </div>
        )}
      </div>
      {/* Progress Bar */}
      {isSupplier && (
        <div className="relative w-full h-4 bg-gray-200 rounded-full mb-6">
          <div
            className="absolute top-0 left-0 h-4 bg-[#1c8f3e] rounded-full transition-all duration-300"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      )}

      {/* Tabs Component */}
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          if (index === 0 || supplierId) {
            setSelectedIndex(index);
          }
        }}
      >
        <TabList className="flex border-0">
          {tabTitles.map((title, index) => (
            <Tab
              key={index}
              className={`px-4 py-2 text-xl text-gray-600 ${index !== 0 && !supplierId ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              selectedClassName="bg-[#FFC726] font-bold text-black-500 rounded-t-lg"
              disabledClassName="text-gray-400"
              disabled={index !== 0 && !supplierId}
            >
              {title}
            </Tab>
          ))}
        </TabList>

        <TabPanel>
          <FormComponent
            ref={formRef}
            isEditable={isSupplier}
            affiliates={affiliates}
            onFormSubmitSuccess={moveToNextTab}
          />
        </TabPanel>

        <TabPanel>
          <AffiliateTable isEditable={isSupplier} />
        </TabPanel>

        <TabPanel>
          <PrimaryContactTable isEditable={isSupplier} />
        </TabPanel>

        <TabPanel>
          <KeyPersonnelTable isEditable={isSupplier} />
        </TabPanel>

        <TabPanel>
          <ShareholdersTable isEditable={isSupplier} />
        </TabPanel>

        <TabPanel>
          <ProjectAndAwardTable isEditable={isSupplier} />
        </TabPanel>

        {/* <TabPanel>
          <PricingComponent isEditable={isSupplier} />
        </TabPanel> */}
      </Tabs>

      <div className="flex justify-end w-full gap-6 mt-6">
        {selectedIndex > 0 || !isSupplier ? (
          <button
            type="button"
            onClick={
              !isSupplier && selectedIndex === 0
                ? handleExit
                : isSupplier
                  ? handleExit
                  : handlePrevious
            }
            className="px-12 py-2 bg-[#F5F5F5] text-lg font-semibold rounded-lg h-[36px]"
          >
            {!isSupplier && selectedIndex === 0
              ? t('exit', 'Exit')
              : isSupplier
                ? t('saveAndExit', 'Save & Exit')
                : t('previous', 'Previous')}
          </button>
        ) : null}
        <button
          type="button"
          onClick={handleNext}
          className="px-8 py-2 text-lg font-semibold bg-primary rounded-lg h-[36px]"
        >
          {isSupplier
            ? selectedIndex === tabTitles.length - 1
              ? t('submit', 'Submit')
              : t('saveAndContinue', 'Save & Continue')
            : selectedIndex === tabTitles.length - 1
              ? t('exit', 'Exit')
              : t('next', 'Next')}
        </button>
      </div>

      {/* Review Modal */}
      <ReviewModal
        isOpen={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onSubmit={() => handleApproval()}
        isTerminatingStep={isTerminatingStep}
        requestId={requestId}
        userId={userId}
        entityName={entityName}
        entityId={entityId}
      ></ReviewModal>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={t('areYouSure', 'Are you sure?')}
          desc={t(
            'initiateReviewProcess',
            'This action will initiate the review process.'
          )}
          onConfirm={reviewApplication}
          onCancel={() => setIsConfirmationModalOpen(false)}
        />
      )}

      {isDocumentRequestModalOpen && (
        <DocumentRequestModal
          isOpen={isDocumentRequestModalOpen}
          onClose={() => setIsDocumentRequestModalOpen(false)}
          onSubmit={(data) => requestAdditionalDocument(data)}
        />
      )}
    </div>
  );
};

export default SupplierManagement;
