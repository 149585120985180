import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import AppButton from './AppButton/AppButton';
import { useGlobalContext } from '../../core/context/GlobalContext';
import { toast } from 'react-toastify';

const MicrosoftLoginButton: React.FC = () => {
  const { instance, accounts } = useMsal();
  const { login } = useGlobalContext();

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      const account = response.account;
      // console.log('microsoft', response);

      if (account) {
        localStorage.setItem('userId', account.homeAccountId);
        localStorage.setItem('userName', account.username);
        localStorage.setItem('accessToken', response.accessToken);
        //login(); // Call context login to set the state and update localStorage
        toast.success('Login successful');
      }
    } catch (error) {
      // console.error(error);
      toast.error('Login failed. Please try again later.');
    }
  };

  //   const handleLogin = () => {
  //     instance.loginPopup(loginRequest).catch(e => {
  //       console.error(e);
  //     });
  //   };

  return (
    <AppButton
      leftIcon={
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/login/microsoft.png`}
          alt="microsoft"
        />
      }
      className="flex gap-2 items-center justify-center w-full text-[1rem] border border-gray-300 py-2 px-1 rounded-md"
      title="Microsoft 365"
      onClick={handleLogin}
    />
  );
};

export default MicrosoftLoginButton;
