import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select'; // Importing react-select
import { useFormik } from 'formik'; // Import Formik
import { useTranslation } from 'react-i18next'; // Import useTranslation
import {
  createStep,
  deleteStep,
  getSteps,
  updateStep,
} from '../../services/workflowServices';
import { toast } from 'react-toastify';
import Delete from '../../shared/utils/Delete/Delete';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../shared/components/CustomTable';

interface FromInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (value: StepItem) => void;
  workflowId: string;
}

export interface PageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId: string;
}

export type StepItem = {
  workflowId?: string;
  workflowStepId?: string;
  stepName: string;
  descriptions: string;
};

const FromInputModal: React.FC<FromInputModalProps> = ({
  isOpen,
  onClose,
  onSelect,
  workflowId,
}) => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [steps, setSteps] = useState<StepItem[]>([]);
  const [selectedStepItem, setSelectedStepItem] = useState<StepItem | null>(
    null
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const pageRequest: IPageRequest = {
    pageNumber: 1,
    pageSize: 10,
    workflowId: workflowId,
  };

  const columns: Column[] = [
    { title: t('ID', 'ID'), dataKey: 'workflowStepId', type: 'string' },
    { title: t('Step', 'Step'), dataKey: 'stepName', type: 'string' },
    {
      title: t('Description', 'Description'),
      dataKey: 'descriptions',
      type: 'string',
    },
    {
      title: t('Actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('Select', 'Select'),
          action: (item: StepItem) => handleSelect(item),
          status: true,
        },
        {
          label: t('Edit', 'Edit'),
          className: 'text-green-500',
          action: (item: StepItem) => handleEdit(item),
          status: true,
        },
        {
          label: t('Delete', 'Delete'),
          className: 'text-red-500',
          action: (item: StepItem) => handleDelete(item),
          status: true,
        },
      ],
    },
  ];

  const deleteModal = (step: StepItem) => {
    setSelectedStepItem(step);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedStep = async () => {
    if (!selectedStepItem) return;
    const { workflowStepId } = selectedStepItem;
    if (!workflowStepId) return;
    const response: any = await deleteStep(workflowStepId);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      setIsDeleteModalOpen(false);
      fetchSteps();
    }
  };

  // Initialize Formik for form handling
  const formik = useFormik({
    initialValues: {
      stepName: '',
      descriptions: '',
      workflowStepId: '',
      workflowId,
    },
    onSubmit: (values) => {
      if (values.stepName && values.descriptions) {
        const newStepItem: StepItem = {
          workflowId,
          stepName: values.stepName,
          descriptions: values.descriptions,
        };

        setSteps([...steps, newStepItem]);
        if (values.workflowStepId) {
          editStep(values);
        } else {
          addStep(newStepItem);
        }
      }
    },
  });

  const handleSelect = (step: any) => {
    onSelect(step);
    onClose();
  };

  const handleEdit = (step: any) => {
    formik.setValues({
      ...formik.values,
      ...step,
    });
  };

  const handleDelete = (step: any) => {
    deleteModal(step);
  };

  const fetchSteps = async () => {
    const response = await getSteps(pageRequest);
    if (response) {
      setSteps(response.data);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  const addStep = async (payload: StepItem) => {
    delete payload.workflowStepId;
    const response = await createStep(payload);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      formik.resetForm();
      fetchSteps();
    }
  };

  const editStep = async (payload: StepItem) => {
    const response = await updateStep(payload);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      formik.resetForm();
      fetchSteps();
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Ensure the target is of type HTMLElement
    if (
      dropdownRef.current &&
      !(dropdownRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setOpenDropdownIndex(null); // Close the dropdown if clicked outside
    }
  };

  useEffect(() => {
    fetchSteps();

    // // Add event listener for clicks outside
    // document.addEventListener('mousedown', handleClickOutside);

    // // Clean up the event listener on component unmount
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside);
    // };
  }, []);

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const selectOptions = [
    { value: 'Checker', label: t('Checker', 'Checker') },
    { value: 'Approver', label: t('Approver', 'Approver') },
    { value: 'Executor', label: t('Executor', 'Executor') },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg w-2/3">
        <h2 className="text-xl mb-4">{t('Step', 'Step')}</h2>

        {/* Form for adding a new step using Formik */}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <div className="mb-2">
              <label className="block mb-1">
                {t('Select Step', 'Select Step')}
              </label>
              {/* React-select dropdown */}
              <Select
                options={selectOptions}
                value={selectOptions.find(
                  (option) => option.value === formik.values.stepName
                )}
                onChange={(option) =>
                  formik.setFieldValue('stepName', option?.value || '')
                }
                placeholder={t('Choose step', 'Choose step')}
                isSearchable
              />
            </div>
            <div className="mb-2">
              <label className="block mb-1">
                {t('Description', 'Description')}
              </label>
              <textarea
                className="border border-gray-300 rounded-lg w-full p-2"
                rows={3}
                name="descriptions"
                value={formik.values.descriptions}
                onChange={formik.handleChange}
                placeholder={t('Enter description', 'Enter description')}
              />
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white p-4 w-[15%] rounded-lg"
            >
              {t('Save', 'Save')}
            </button>
          </div>
        </form>
        <CustomTable
          data={steps}
          columns={columns}
          pageCount={pageCount} // Pass the total page count
          showPagination={true} // Control whether to show pagination
          showSearch={true} // Control whether to show the search box
          onSearch={handleSearch} // Pass the search handler
        />

        {/* Close Button */}
        <div className="w-full flex justify-end">
          <button
            onClick={onClose}
            className="mt-4 p-4 px-6  rounded-lg bg-gray-300"
          >
            {t('Close', 'Close')}
          </button>
        </div>

        {isDeleteModalOpen && (
          <Delete
            title={t('Are you sure', 'Are you sure')}
            desc={t(
              'This action is permanent, all data would be lost.',
              'This action is permanent, all data would be lost.'
            )}
            onDelete={deleteSelectedStep}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default FromInputModal;
