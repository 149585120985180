export const ModuleCard = [
  {
    id: 1,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyCaution.svg`,
    title: 'Quality Control Basics',
    description:
      'Learn the fundamental principles of quality control and how they apply to your industry.',
  },
  {
    id: 2,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyCaution.svg`,
    title: 'Quality Assurance Strategies',
    description:
      'This module covers effective quality assurance strategies to help you improve verification speed.',
  },
  {
    id: 3,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyProcedure.svg`,
    title: 'Getting Verified Quicker',
    description:
      'Understand the steps and best practices to ensure quicker verification in your processes.',
  },
  {
    id: 4,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyCaution.svg`,
    title: 'Audit & Inspection',
    description:
      'Learn how audits and inspections help in maintaining high standards of quality control.',
  },
  {
    id: 5,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyCaution.svg`,
    title: 'Quality Control Basics',
    description:
      'Learn the fundamental principles of quality control and how they apply to your industry.',
  },
  {
    id: 6,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyCaution.svg`,
    title: 'Quality Assurance Strategies',
    description:
      'This module covers effective quality assurance strategies to help you improve verification speed.',
  },
  {
    id: 7,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyProcedure.svg`,
    title: 'Getting Verified Quicker',
    description:
      'Understand the steps and best practices to ensure quicker verification in your processes.',
  },
  {
    id: 8,
    image: `${process.env.PUBLIC_URL}/assets/images/supplierApplication/safetyCaution.svg`,
    title: 'Audit & Inspection',
    description:
      'Learn how audits and inspections help in maintaining high standards of quality control.',
  },
];
