import React, { useState, ChangeEvent } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface AppHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPersonnel?: any; // Define a proper type for selectedPersonnel if needed
  isEditable?: boolean;
}

const AppHistoryModal: React.FC<AppHistoryModalProps> = ({
  isOpen,
  onClose,
  selectedPersonnel,
  isEditable = false,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    description: selectedPersonnel?.description || '',
    currentLevel: selectedPersonnel?.currentLevel || '',
    preparedBy: selectedPersonnel?.preparedBy || '',
    lastAssignedOn: selectedPersonnel?.lastAssignedOn || '',
  });

  // Handle input change for editable formAppHistoryModal.tsx
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-4xl">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {selectedPersonnel
              ? t('viewPersonnel', 'View Personnel')
              : t('addNewPersonnel', 'Add New Personnel')}
          </h2>
          <button className="text-red-500 text-xl" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        {/* Divider */}
        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        {/* Modal body */}
        <div className="modal-body grid grid-cols-2 gap-4 mb-4">
          {/* Description */}
          <div className="form-group">
            <label className="block mb-1">
              {t('description', 'Description')}
              {isEditable && <span className="text-red-500"> *</span>}
            </label>

            <input
              type="text"
              name="description"
              disabled={!isEditable}
              value={formData.description}
              onChange={handleInputChange}
              className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
            />
          </div>

          {/* Current Level */}
          <div className="form-group">
            <label className="block mb-1">
              {t('currentLevel', 'Current Level')}
              {isEditable && <span className="text-red-500"> *</span>}
            </label>

            <input
              type="text"
              name="currentLevel"
              disabled={!isEditable}
              value={formData.currentLevel}
              onChange={handleInputChange}
              className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
            />
          </div>

          {/* Prepared By */}
          <div className="form-group">
            <label className="block mb-1">
              {t('preparedBy', 'Prepared By')}
              {isEditable && <span className="text-red-500"> *</span>}
            </label>

            <input
              type="text"
              name="preparedBy"
              disabled={!isEditable}
              value={formData.preparedBy}
              onChange={handleInputChange}
              className="w-full border rounded-lg text-sm p-2 h-11"
            />
          </div>

          {/* Last Assigned On */}
          <div className="form-group">
            <label className="block mb-1">
              {t('lastAssignedOn', 'Last Assigned On')}
              {isEditable && <span className="text-red-500"> *</span>}
            </label>

            <input
              type="date"
              name="lastAssignedOn"
              disabled={!isEditable}
              value={formData.lastAssignedOn}
              onChange={handleInputChange}
              className="w-full border rounded-lg text-sm p-2 h-11"
            />
          </div>
        </div>

        {/* Divider */}
        <div className="divider my-2 !bg-[#E5E5E5]"></div>

        {/* Footer */}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-6 py-2 rounded-md mr-2"
          >
            {t('close', 'Close')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppHistoryModal;
