import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FromInputModal, { StepItem } from './FromInputModal';
import ToInputModal from './ToInputModal';
import Select, { OptionsOrGroups, GroupBase } from 'react-select';
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import { getUsers } from '../../services/AccountServices';
import { useLocation } from 'react-router-dom';
import {
  createProcess,
  getCommands,
  getProcessById,
  updateProcess,
} from '../../services/workflowServices';
import { toast } from 'react-toastify';
import { CommandData } from '../commandConfig/CommandConfig';
import { useTranslation } from 'react-i18next';

interface OptionType {
  label: string;
  value: string;
}

interface ApproverData {
  userId: string;
}

interface PageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId: string;
}

const StepConfig: React.FC = () => {
  const { t } = useTranslation();
  const [isFromModalOpen, setIsFromModalOpen] = useState(false);
  const [isToModalOpen, setIsToModalOpen] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState<StepItem | null>(null);
  const [selectedTo, setSelectedTo] = useState<StepItem | null>(null);
  const [commandData, setCommandData] = useState<CommandData[]>([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [isCommandDataLoaded, setIsCommandDataLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const workflowId = location.state?.workflowId;
  const workflowStepRelationId = location.state?.workflowStepRelationId;
  const pageRequest: PageRequest = {
    pageNumber: 1,
    pageSize: 10,
    workflowId: workflowId,
  };

  // Multi-select options
  const assignedUserOptions: OptionsOrGroups<
    OptionType,
    GroupBase<OptionType>
  > = [
    { label: 'User 1', value: 'D54210BD-35EB-483C-8B25-08DCE17723C9' },
    { label: 'User 2', value: 'user2' },
    { label: 'User 3', value: 'user3' },
  ];

  const actionTypeOptions: OptionsOrGroups<
    OptionType,
    GroupBase<OptionType>
  > = [
    { label: t('approved', 'Approved'), value: '0' },
    { label: t('rejected', 'Rejected'), value: '1' },
    { label: t('canceled', 'Canceled'), value: '2' },
    { label: t('inProgress', 'InProgress'), value: '3' },
  ];

  const commandOptions = commandData.map((command) => ({
    label: command.descriptions,
    value: command.workflowCommandId,
  }));

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
      description: '',
      assignedUsers: [] as OptionType[], // Array of options for assigned users
      actionType: [] as OptionType[], // Array of options for action type
      reviewPeriod: '',
      sequence: '', // Dropdown for sequence
      terminatingStep: false, // Checkbox for terminatingStep
      autoAssignProcessor: false, // Checkbox for autoAssignProcessor
    },
    validationSchema: Yup.object({
      from: Yup.object().required(t('fromRequired', 'From is required')),
      to: Yup.object().required(t('toRequired', 'To is required')),
      // description: Yup.string().required(t('descriptionRequired', 'Description is required')),
      reviewPeriod: Yup.string().required(
        t('reviewPeriodRequired', 'Review period is required')
      ),
      sequence: Yup.number().required(
        t('sequenceRequired', 'Sequence is required')
      ),
    }),

    onSubmit: (values) => {
      const payload = mapFormValuesToPayload(values);
      if (workflowStepRelationId) {
        payload.workflowStepRelationId = workflowStepRelationId;
        editProcess(payload);
      } else {
        delete payload.workflowStepRelationId;
        addProcess(payload);
      }
    },
  });

  const addProcess = async (payload: any) => {
    const response = await createProcess(payload);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
      goBack();
    }
  };

  const editProcess = async (payload: any) => {
    const response = await updateProcess(payload);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
      goBack();
    }
  };

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const mapFormValuesToPayload = (formValues: any) => {
    const payload = {
      workflowStepRelationId: formValues.workflowStepRelationId || null,
      duration: parseInt(formValues.reviewPeriod, 10),
      sequence: formValues.sequence,
      terminatingStep: formValues.terminatingStep,
      autoAssignProcessor: formValues.autoAssignProcessor,

      workflowId: formValues.from.workflowId,
      fromStepId: formValues.from.workflowStepId,
      toStepId: formValues.to.workflowStepId,
      stepApprovers: formValues.assignedUsers.map((user: OptionType) => ({
        userId: user.value,
      })),
      workflowStepActions: formValues.actionType.map((action: any) => ({
        workflowCommandId: action.value,
      })),
    };

    return payload;
  };

  const getStepByWorkflowStepRelationId = async (
    workflowStepRelationId: string
  ) => {
    try {
      const response = await getProcessById(workflowStepRelationId);
      if (response?.isSuccess) {
        setSelectedFrom(response?.data?.fromStep);
        setSelectedTo(response?.data?.toStep);

        formik.setFieldValue('from', response?.data?.fromStep);
        formik.setFieldValue('to', response?.data?.toStep);
        formik.setValues({
          ...formik.values,
          from: response?.data.fromStep,
          to: response?.data?.toStep,
          reviewPeriod: response?.data.duration || 0,
          sequence: response?.data.sequence || 0,
          terminatingStep: response?.data.terminatingStep || false,
          autoAssignProcessor: response?.data.autoAssignProcessor || false,

          assignedUsers: response?.data.stepApprovers.map((approver: any) => {
            const matchingOption = userOptions.find(
              (option) => option.value === approver.userId
            );
            return matchingOption ? matchingOption : { label: '', value: '' };
          }),

          actionType:
            response?.data?.workflowStepActions
              .map((commandAction: any) => {
                const matchingOption = commandData.find(
                  (option: any) =>
                    option.workflowCommandId.toLowerCase() ===
                    commandAction.workflowCommandId.toLowerCase()
                );
                return matchingOption
                  ? {
                      label: matchingOption.actionType,
                      value: commandAction.workflowCommandId,
                    }
                  : null;
              })
              .filter(Boolean) || [],
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const fetchUsers = async () => {
    const stepResponse = await getUsers();

    if (stepResponse) {
      const userOptions = stepResponse.map((user: any) => ({
        label: user.fullName,
        value: user.employeeId,
      }));

      setUserOptions(userOptions);
      setIsUserDataLoaded(true);
    } else {
      toast.error(stepResponse?.error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchCommandList = async () => {
    try {
      const response = await getCommands({
        pageNumber: 1,
        pageSize: 100,
        workflowId: workflowId,
      });
      if (response) {
        setCommandData(response.data);
        setIsCommandDataLoaded(true);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchCommandList(); // Fetch command data when the component mounts or workflowId changes
  }, [workflowId]);

  useEffect(() => {
    if (isCommandDataLoaded && workflowStepRelationId) {
      // Only call if command data is loaded
      getStepByWorkflowStepRelationId(workflowStepRelationId);
    }
  }, [isCommandDataLoaded, isUserDataLoaded, workflowStepRelationId]);

  return (
    <div>
      <div className="w-[100%] p-4 my-4">
        <div className="flex justify-start">
          <button
            type="button"
            onClick={goBack}
            className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-lg"
          >
            {t('back', 'Back')}
          </button>
        </div>
        <h1 className="text-2xl my-6">
          {t('stepConfiguration', 'Step Configuration')}
        </h1>
        <form
          className="mt-4 flex flex-col gap-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex space-x-4 mb-4">
            <div>
              <label>{t('from', 'From')}</label>
              <div className="flex items-center">
                <input
                  type="text"
                  value={selectedFrom?.stepName || ''}
                  onClick={() => setIsFromModalOpen(true)} // Open From modal
                  className="border p-2"
                  onChange={formik.handleChange}
                  placeholder={t('selectFrom', 'Select From')}
                />
                <button type="button" onClick={() => setIsFromModalOpen(true)}>
                  <i className="icon-search" /> {/* Icon */}
                </button>
              </div>
            </div>
            <div>
              <label>{t('to', 'To')}</label>
              <div className="flex items-center">
                <input
                  type="text"
                  value={selectedTo?.stepName || ''}
                  onClick={() => setIsToModalOpen(true)} // Open To modal
                  className="border p-2"
                  placeholder={t('selectTo', 'Select To')}
                  onChange={formik.handleChange}
                />
                <button type="button" onClick={() => setIsToModalOpen(true)}>
                  <i className="icon-search" /> {/* Icon */}
                </button>
              </div>
            </div>
          </div>

          {/* Description */}
          {/* <div className="mb-4">
            <label>{t('description', 'Description')}</label>
            <textarea
              className="border p-2 w-full"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
          </div> */}

          {/* Assigned Users MultiSelect */}
          <div className="mb-4">
            <label>{t('assignedUsers', 'Assigned Users')}</label>
            <Select
              isMulti
              options={userOptions}
              value={formik.values.assignedUsers.map((user) =>
                userOptions.find((option) => option.value === user.value)
              )}
              onChange={(selected) =>
                formik.setFieldValue(
                  'assignedUsers',
                  selected.map((user) => ({
                    label: user.label,
                    value: user.value,
                  }))
                )
              }
              placeholder={t('selectAssignedUsers', 'Select Assigned Users')}
              className="w-full"
            />
          </div>

          {/* Action Type MultiSelect */}
          <div className="mb-4">
            <label>{t('actionType', 'Action Type')}</label>
            <Select
              isMulti
              options={commandOptions}
              value={formik.values.actionType}
              onChange={(selected) =>
                formik.setFieldValue('actionType', selected)
              }
              placeholder={t('selectActionType', 'Select Action Type')}
              className="w-full"
            />
          </div>

          {/* Review Period */}
          <div className="mb-4">
            <label>{t('reviewPeriod', 'Review Period (hrs)')}</label>
            <select
              name="reviewPeriod"
              className="border p-2 w-full h-[38px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reviewPeriod}
            >
              <option value="">
                {t('selectReviewPeriod', 'Select review period')}
              </option>
              <option value="24">{t('24Hours', '24 hours')}</option>
              <option value="48">{t('48Hours', '48 hours')}</option>
              <option value="72">{t('72Hours', '72 hours')}</option>
            </select>
          </div>

          {/* Sequence Dropdown */}
          <div className="mb-4">
            <label>{t('sequence', 'Sequence')}</label>
            <select
              name="sequence"
              className="border p-2 w-full h-[38px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sequence}
            >
              <option value="">{t('selectSequence', 'Select sequence')}</option>
              {[...Array.from({ length: 10 }, (_, num) => num + 1)].map(
                (num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="flex items-center mb-4">
            <div className="flex items-center">
              <input
                className="mr-2 w-[15px] h-[15px] bg-primary-500 rounded-lg text-white"
                type="checkbox"
                name="terminatingStep"
                checked={formik.values.terminatingStep}
                onChange={formik.handleChange}
              />
              <label htmlFor="terminatingStep" className="mr-4">
                {t('terminatingStep', 'Terminating Step')}
              </label>
            </div>
            <div className="flex items-center">
              <input
                className="mr-2 w-[15px] h-[15px] bg-primary-500 rounded-lg text-white"
                type="checkbox"
                name="autoAssignProcessor"
                checked={formik.values.autoAssignProcessor}
                onChange={formik.handleChange}
              />
              <label htmlFor="autoAssignProcessor">
                {t('autoAssignProcessor', 'Auto Assign Processor')}
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="bg-green-500 text-white p-4 w-[10%] rounded-lg"
          >
            {t('submit', 'Submit')}
          </button>
        </form>
      </div>

      {/* From Modal */}
      <FromInputModal
        isOpen={isFromModalOpen}
        onClose={() => setIsFromModalOpen(false)}
        onSelect={(item) => {
          setSelectedFrom(item);
          formik.setFieldValue('from', item);
          setIsToModalOpen(false);
        }}
        workflowId={workflowId}
      />

      <FromInputModal
        isOpen={isToModalOpen}
        onClose={() => setIsToModalOpen(false)}
        onSelect={(item) => {
          setSelectedTo(item);
          formik.setFieldValue('to', item);
          setIsToModalOpen(false);
        }}
        workflowId={workflowId}
      />

      {/* To Modal */}
      {/* <ToInputModal
        isOpen={isToModalOpen}
        onClose={() => setIsToModalOpen(false)}
        onSelect={(item) => {
          setSelectedTo(item);
          formik.setFieldValue('to', item);
        }}
      /> */}
    </div>
  );
};

export default StepConfig;
