import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface DocumentRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const DocumentRequestModal: React.FC<DocumentRequestModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [infoList, setInfoList] = useState<string[]>([]); // Holds the list of added items
  const [inputValue, setInputValue] = useState<string>(''); // Holds the entered value for type
  const [error, setError] = useState<string | null>(null); // Holds any error message

  const formik = useFormik({
    initialValues: {
      reason: '',
      remarks: '',
    },
    validationSchema: Yup.object({
      reason: Yup.string().required(t('reasonRequired', 'Reason is required')),
      remarks: Yup.string().required(
        t('remarksRequired', 'Remarks are required')
      ),
    }),
    onSubmit: (values) => {
      onSubmit({ ...values, infoList });
    },
  });

  // Handles the addition of a new item to the list
  const handleAddItem = () => {
    if (!inputValue) {
      setError(t('enterInformationType', 'Enter information type.')); // Set error if input is empty
      return;
    }
    if (infoList.includes(inputValue)) {
      setError(
        t('documentTypeAdded', 'This document type has already been added.')
      ); // Set error for duplicates
      return;
    }
    setInfoList([...infoList, inputValue]); // Add the entered value to the list
    setInputValue(''); // Reset input field after adding
    setError(null); // Clear error after successful addition
  };

  // Handles removal of an item from the list
  const handleRemoveItem = (item: string) => {
    setInfoList(infoList.filter((info) => info !== item)); // Filter out the selected item
  };

  if (!isOpen) return null; // Don't render if not open

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose} />
      <div className="bg-white rounded-2xl shadow-lg p-6 z-10 w-full max-w-4xl inline-flex flex-col justify-center items-center">
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col gap-4"
        >
          <div className="w-full">
            <div className="flex items-center justify-between">
              <h2 className="text-xl flex justify-start font-semibold mb-4">
                {t(
                  'requestAdditionalDocuments',
                  'Request Additional Documents'
                )}
              </h2>
              <FaTimes onClick={onClose} className="cursor-pointer" />
            </div>

            {/* Divider */}
            <div className="divider my-2 mb-6 !bg-[#E5E5E5]"></div>

            <p className="flex justify-start text-center">
              {t(
                'notifySupplier',
                'Notify a supplier of additional information required.'
              )}
            </p>
          </div>

          <div className="mt-4 w-full">
            <label
              htmlFor="reason"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {t('reasonForRequest', 'Reason for request*')}
            </label>
            <select
              id="reason"
              name="reason"
              className={`mt-1 block w-full p-2 border border-gray-300 h-[3.2rem] rounded-lg ${formik.touched.reason && formik.errors.reason ? 'border-red-500' : ''}`}
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">{t('selectReason', 'Select a reason')}</option>
              <option value="Incomplete documentation">
                {t('incompleteDocumentation', 'Incomplete documentation')}
              </option>
              <option value="Complete documentation">
                {t('completeDocumentation', 'Complete documentation')}
              </option>
            </select>
            {formik.touched.reason && formik.errors.reason && (
              <p className="text-red-500 text-sm">{formik.errors.reason}</p>
            )}
          </div>

          {/* <div className="mt-4 w-full">
            <div className="flex items-center justify-between">
              <h3 className="font-semibold mb-1">
                {t('additionalInformationRequired', 'Additional Information Required')}
              </h3>
            </div>
            <div className="flex w-full justify-end">
              <div className="w-56">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={t('enterInformationType', 'Enter information type')}
                  className="border border-gray-300 rounded-lg px-3 py-2 text-sm h-[3.2rem]"
                />
 
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
              <button
                className="ml-2 border border-gray-300 rounded-lg h-[3.2rem] px-6 py-2 text-blue-500"
                onClick={handleAddItem}
                type="button"
              >
                {t('add', '+ Add')}
              </button>
            </div>

            <div className="mt-2">
              {infoList.length > 0 ? (
                infoList.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center mt-1"
                  >
                    <span>{item}</span>
                    <button
                      className="text-red-500"
                      type="button"
                      onClick={() => handleRemoveItem(item)}
                    >
                      {t('remove', 'Remove')}
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">
                  {t('noAdditionalInformation', 'No additional information added.')}
                </p>
              )}
            </div>
          </div> */}

          <div className="mt-4 w-full">
            <label
              htmlFor="remarks"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {t('commentsRemarks', 'Comments/Remarks*')}
            </label>
            <textarea
              id="remarks"
              name="remarks"
              className={`mt-1 block w-full p-2 border border-gray-300 rounded-md ${formik.touched.remarks && formik.errors.remarks ? 'border-red-500' : ''}`}
              placeholder={t('enterRemarks', 'Enter Remarks')}
              rows={3}
              value={formik.values.remarks}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.remarks && formik.errors.remarks && (
              <p className="text-red-500 text-sm">{formik.errors.remarks}</p>
            )}
          </div>

          <div className="flex justify-between mt-6 w-full">
            {/* <button
              className="text-blue-500 bg-slate-100 px-4 py-2"
              type="button"
              onClick={onClose}
            >
              {t('viewApplicationHistory', 'View Application History')}
            </button> */}
            <div className="flex space-x-2">
              <button
                className="px-8 py-2 border rounded-lg"
                type="button"
                onClick={onClose}
              >
                {t('cancel', 'Cancel')}
              </button>
              <button
                className="px-8 py-2 bg-green-500 text-white rounded-lg"
                type="submit"
              >
                {t('send', 'Send')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentRequestModal;
