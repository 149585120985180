import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RegisterValidationSchema } from '../ValidationSchema';
import AppButton from '../../../shared/components/AppButton/AppButton';
import '../SignUp/Register.css';
import { useNavigate } from 'react-router-dom';
import { Register as RegistrationService } from '../../../services/AccountServices';
import { ClipLoader } from 'react-spinners';
import Navbar from '../../../shared/layouts/NavBar/Navbar';
import GoogleLoginButton from '../../../shared/components/GoogleLoginButton';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';

interface RegisterFormValues {
  firstName?: string;
  lastName?: string;
  userName: string;
  password: string;
  confirmPassword: string;
  email: string;
  phoneNumber: string;
  termsAccepted: boolean;
}

const Register: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      confirmPassword: '',
      email: '',
      phoneNumber: '',
      termsAccepted: false,
    },
    validationSchema: RegisterValidationSchema,
    onSubmit: async (values: RegisterFormValues) => {
      try {
        const payload = {
          ...values,
          phoneNumber: `+${values.phoneNumber}`,
        };
        setIsLoading(true);
        const response = await RegistrationService(payload);

        if (response.isSuccess) {
          navigate('/otp', { state: { email: values.email } });

          return response.data;
        }
        // console.log(response);
      } catch {
        toast.error('Error signing up');
      }
      setIsLoading(false);
    },
  });
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    formik.setFieldValue('email', email);
    formik.setFieldValue('userName', email); // Automatically set userName to email
  };

  const handleOutlookSignUp = () => {
    toast.info(
      t(
        'outlookSignUpNotAvailable',
        'Outlook Sign up is not available at the moment'
      )
    );
  };

  return (
    <div>
      <Navbar />
      <div className="register-form">
        <div className="form-header">
          <h4 className="text-2xl font-semibold mt-5">
            {t(
              'welcomeToAnpEsscPortal',
              'Welcome to Autoridade Nacional Do Petroleo (ANP) Timor-Leste - ESSC PORTAL'
            )}
          </h4>
        </div>

        <form onSubmit={(e) => e.preventDefault()} className="register-card">
          <h1 className="text-2xl font-bold text-center mb-6">
            {t('register', 'Register')}
          </h1>

          <div className="social-login-buttons">
            <GoogleOAuthProvider clientId={clientId}>
              <div className="">
                <GoogleLoginButton />
              </div>
            </GoogleOAuthProvider>
            {/* <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/goggle.png`}
                  alt="goggle"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[10px] border border-gray-300 py-2 px-1 rounded-md"
              title={t('google', 'Google')}
              onClick={() => navigate('www.google.com')}
            /> */}
            <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/microsoft.png`}
                  alt="microsoft"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[10px] border border-gray-300 py-2 px-1 rounded-md"
              title={t('microsoft365', 'Microsoft 365')}
              onClick={handleOutlookSignUp}
            />
            <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/outlook.png`}
                  alt="outlook"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[10px] border border-gray-300 py-2 px-1 rounded-md"
              title={t('outlook', 'Outlook')}
              onClick={() => navigate('www.outlook.com')}
            />
          </div>

          <div className="divider-container">
            <div className="divider"></div>
            <span className="divider-text">{t('or', 'OR')}</span>
            <div className="divider"></div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              {t('firstName', 'First Name')}*
            </label>
            <input
              type="text"
              id="firstName"
              placeholder={t('enterFirstName', 'Enter First Name')}
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <span className="text-red-500 text-sm">
                {formik.errors.firstName}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              {t('lastName', 'Last Name')}*
            </label>
            <input
              type="text"
              id="lastName"
              placeholder={t('enterLastName', 'Enter Last Name')}
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <span className="text-red-500 text-sm">
                {formik.errors.lastName}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t('email', 'Email')}*
            </label>
            <input
              type="email"
              id="email"
              placeholder={t('enterEmail', 'Enter Email Address')}
              name="email"
              onChange={handleEmailChange}
              value={formik.values.email}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.email && formik.errors.email && (
              <span className="text-red-500 text-sm">
                {formik.errors.email}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              {t('phoneNumber', 'phoneNumber')}*
            </label>
            {/* <input
              maxLength={15}
              minLength={6}
              type="text"
              id="phoneNumber"
              placeholder={t('enterphoneNumber', '+234 803 123 4567')}
              name="phoneNumber"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            /> */}

            <PhoneInput
              country={'us'}
              containerStyle={{ width: '410px' }}
              inputStyle={{ width: '100%' }}
              placeholder="+234 803 123 4567"
              value={formik.values.phoneNumber}
              onChange={(value) => formik.setFieldValue('phoneNumber', value)}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <span className="text-red-500 text-sm">
                {formik.errors.phoneNumber}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t('password', 'Password')}
            </label>
            <input
              type="password"
              id="password"
              placeholder={t('enterPassword', 'Enter Password')}
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.password && formik.errors.password && (
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              {t('confirmPassword', 'Confirm Password')}
            </label>
            <input
              type="password"
              id="confirmPassword"
              placeholder={t('confirmPassword', 'Confirm Password')}
              name="confirmPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <span className="text-red-500 text-sm">
                  {formik.errors.confirmPassword}
                </span>
              )}
          </div>

          <div className="mb-4 flex gap-4">
            <input
              type="checkbox"
              id="termsAccepted"
              name="termsAccepted"
              onChange={formik.handleChange}
              checked={formik.values.termsAccepted}
              className=" w-5 h-10  bg-[#FFC726] text-black "
            />
            <div className="flex flex-col ">
              <div>
                <span className="text-[11px] text-gray-400">
                  {t(
                    'iUnderstand',
                    'I understand and agree to ESSC Privacy Policy and Terms and conditions and I authorise ESSC to contact me for account management purposes via the contact information I provide.'
                  )}
                </span>
              </div>
              {formik.touched.termsAccepted && formik.errors.termsAccepted && (
                <span className="text-red-500 text-sm">
                  {formik.errors.termsAccepted}
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <AppButton
              className="w-full bg-primary text-black font-semibold p-4 rounded-md"
              title={t('register', 'Register')}
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            />
          </div>

          <div className="copyright">
            <h6>
              {t(
                'copyright',
                'Copyright  2024 ANP ESSC Portal. All rights reserved'
              )}
            </h6>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
