import React, { useRef, useEffect } from 'react';

interface DropdownMenuItem {
  label: string;
  action: () => void; // Function to execute when item is clicked
  className?: string; // Optional class name for styling
}

interface DropdownMenuProps {
  open: boolean;
  onClose: () => void;
  items: DropdownMenuItem[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  open,
  onClose,
  items,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) return null;

  return (
    <div
      ref={dropdownRef}
      className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded-lg shadow-lg z-10"
    >
      <ul>
        {items.map((item, index) => (
          <li
            key={index}
            className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${item.className}`}
            onClick={() => {
              item.action(); // Invoke the action for the item
              onClose(); // Close the dropdown
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenu;
