import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
// import { NavLink } from 'react-router-dom';
import AppButton from '../../components/AppButton/AppButton';
// import { getNavData } from './index';
import './NavBar.css';
import { useNavigate, useLocation } from 'react-router-dom';
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Available languages
  const languages = [
    { code: 'en-US', label: 'English' },
    { code: 'pt', label: 'Português' },
    { code: 'tet', label: 'Tetum' },
  ];

  // State to control the dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].label); // Default to the first language
  const currentPath = location.pathname;

  // Function to change language
  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
    const selectedLang = languages.find((lang) => lang.code === code)?.label;
    setSelectedLanguage(selectedLang || selectedLanguage);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  // Get navigation data using the function
  // const navData = getNavData(t);

  return (
    <div className="navbar">
      <div className="flex gap-4">
        <div className="navbar-logo flex items-center space-x-2">
          <img
            className="nav-logo w-12 sm:w-12 md:w-12 lg:w-16"
            src={`${process.env.PUBLIC_URL}/assets/images/navbar/AnpLogo.png`}
            alt={t('anpLogo', 'ANP Logo')}
          />
          <h6 className="logo-text font-light sm:font-light md:font-semibold lg:font-bold text-[10px] sm:text-[10px] md:text-[10px] lg:text-[14px]">
            {t(
              'enterpriseSupportServiceCenter',
              'Enterprise Support Service Center'
            )}
          </h6>
        </div>
        <div className="navbar-logo">
          <img
            className="nav-logo w-12 sm:w-12 md:w-12 lg:w-16"
            src={`${process.env.PUBLIC_URL}/assets/images/navbar/AnpLogo2.png`}
            alt={t('anpLogo2', 'ANP Logo')}
          />
          <h6 className="logo-text2 font-light sm:font-light md:font-semibold lg:font-bold text-[10px] sm:text-[10px] md:text-[10px] lg:text-[14px]">
            {t(
              'autoridadeNacionalDoPetroleo',
              'Autoridade Nacional Do Petroleo'
            )}
            <br />
            {t('timorLeste', 'Timor - Leste')}
          </h6>
        </div>
      </div>
      {/* <div className="nav-links">
        {navData.map(({ title, path }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t(title, title)}
          </NavLink>
        ))}
      </div> */}
      <div className="nav-buttons flex items-center gap-5">
        <div className="relative">
          {/* Container for the button and selected language */}
          <div
            className="flex items-center border rounded-lg border-gray-300 cursor-pointer bg-white px-2 py-1"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {/* World Icon */}
            {/* <button className="dropdown-toggle-button p-2 rounded-full bg-gray-200 text-gray-800">
              🌐
            </button> */}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/navbar/global.svg`}
              alt="goggle"
            />

            {/* Selected Language with Arrow */}
            <div className="flex items-center gap-2 ml-2">
              <span className="text-gray-800">{selectedLanguage}</span>
              <FaChevronDown className="text-gray-600" />
            </div>
          </div>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <ul className="dropdown-menu absolute right-0 mt-2 bg-white shadow-lg rounded-md border border-gray-300">
              {languages.map(({ code, label }) => (
                <li
                  key={code}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleLanguageChange(code)}
                >
                  {label}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Render the Register button only if the current route is '/login' */}
        {currentPath === '/login' && (
          <AppButton
            onClick={() => navigate('/register')}
            className="primary"
            title="Register"
          />
        )}

        {/* Render the Login button only if the current route is '/register' */}
        {currentPath === '/register' && (
          <AppButton
            onClick={() => navigate('/login')}
            className="secondary"
            title="Login"
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
