import * as React from 'react';

import {
  ChartComponent,
  SeriesCollectionDirective,
  Highlight,
  SeriesDirective,
  Inject,
  Tooltip,
  DateTime,
  SplineAreaSeries,
  Legend,
} from '@syncfusion/ej2-react-charts';

interface AreaChatProps {
  seriesConfigurations: any;
  chartProperties: any;
}
const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }`;
/**
 * Area sample
 */
const SplineArea: React.FC<AreaChatProps> = ({
  seriesConfigurations,
  chartProperties,
}) => {
  return (
    <div className="control-pane">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section">
        <ChartComponent id="charts" {...chartProperties}>
          <Inject
            services={[SplineAreaSeries, DateTime, Tooltip, Legend, Highlight]}
          />
          <SeriesCollectionDirective>
            {seriesConfigurations.map((seriesConfig: any, index: number) => (
              <SeriesDirective key={index} {...seriesConfig} />
            ))}
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};
export default SplineArea;
