import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable, { Column } from '../../../../shared/components/CustomTable';
import AppHistoryModal from './appHistoryModal';

const ProcessedTaskTabContent: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPersonnel, setSelectedPersonnel] = useState<any | null>(null);
  const [processedTasks, setProcessedTasks] = useState<any>([]);

  // Handlers for actions
  const handleView = (item: any) => {
    setSelectedPersonnel(item);
    setIsModalOpen(true); // Open the modal to view the item
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedPersonnel(null); // Clear the selected personnel to add new task/personnel
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPersonnel(null);
  };

  // Define column structure
  const columns: Column[] = [
    {
      title: t('description', 'Description'),
      dataKey: 'description',
      type: 'string',
    },
    {
      title: t('currentLevel', 'Current Level'),
      dataKey: 'currentLevel',
      type: 'string',
    },
    {
      title: t('preparedBy', 'Prepared By'),
      dataKey: 'preparedBy',
      type: 'string',
    },
    {
      title: t('lastAssignedOn', 'Last Assigned On'),
      dataKey: 'lastAssignedOn',
      type: 'string',
    }, // Use date type if needed
    { title: t('status', 'Status'), dataKey: 'status', type: 'string' },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // No data key needed for action, but it's still required
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: 'btn-view',
          action: (item: any) => handleView(item),
          status: true,
        },
      ],
    },
  ];

  return (
    <div>
      <CustomTable
        data={processedTasks}
        columns={columns}
        showPagination={true}
        showSearch={true}
        onAddButtonClick={openModal}
      />

      {isModalOpen && (
        <AppHistoryModal
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedPersonnel={selectedPersonnel}
          isEditable={selectedPersonnel ? false : true} // If selectedPersonnel exists, it's in view mode; otherwise, editable
        />
      )}
    </div>
  );
};

export default ProcessedTaskTabContent;
