export interface MenuItem {
  id: number;
  name: string;
  link: string;
  icon: string;
  children?: MenuItem[];
}

// export const menuItems: MenuItem[] = [
//   {
//     id: 1,
//     name: 'Home',
//     link: '/dashboard',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/home.svg`,
//   },
//   {
//     id: 2,
//     name: 'Supplier Management',
//     link: '/supplier-application',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
//   },
//   {
//     id: 3,
//     name: 'Catalogue',
//     link: '/catalogue',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/task.svg`,
//   },
//   {
//     id: 4,
//     name: 'E-Procurement',
//     link: '/procurement',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/bill.svg`,
//   },
//   {
//     id: 5,
//     name: 'Electronic Management',
//     link: '/electronic-management',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
//   },
//   {
//     id: 6,
//     name: 'Supplier Requests',
//     link: '/supplier-verifications',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
//   },
//   {
//     id: 7,
//     name: 'Notification',
//     link: '/notification',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/notification.svg`,
//   },
//   {
//     id: 8,
//     name: 'Support',
//     link: '/support',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/message-question.svg`,
//   },
//   {
//     id: 9,
//     name: 'Settings',
//     link: '/workflow',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
//   },
//   {
//     id: 10,
//     name: 'Navigations',
//     link: '/navigation-menu',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
//   },
//   {
//     id: 11,
//     name: 'Roles',
//     link: '/role-permission',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
//   },
//   {
//     id: 12,
//     name: 'Users',
//     link: '/user-management',
//     icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
//   },
// ];

export const menuItems: MenuItem[] = [
  {
    id: 1,
    name: 'Home',
    link: '/dashboard',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/home.svg`,
  },
  {
    id: 2,
    name: 'Supplier Management',
    link: '/supplier-application',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
  },
  {
    id: 3,
    name: 'Catalogue',
    link: '/catalogue',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/task.svg`,
  },
  {
    id: 4,
    name: 'E-Procurement',
    link: '/procurement',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/bill.svg`,
  },
  {
    id: 5,
    name: 'Electronic Management',
    link: '/electronic-management',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
  },
  {
    id: 6,
    name: 'Supplier Requests',
    link: '/supplier-requests',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
  },
  {
    id: 7,
    name: 'Application Desk',
    link: '/application-desk',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
  },
  {
    id: 8,
    name: 'Notification',
    link: '/notification',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/notification.svg`,
  },
  {
    id: 9,
    name: 'Support',
    link: '/support',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/message-question.svg`,
  },
  {
    id: 10,
    name: 'Settings',
    link: '/settings',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
    children: [
      {
        id: 1,
        name: 'Workflow',
        link: '/settings/workflow',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 2,
        name: 'Navigations',
        link: '/settings/menu',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 3,
        name: 'Roles',
        link: '/settings/roles',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 4,
        name: 'Users',
        link: '/settings/users',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
    ],
  },
];
