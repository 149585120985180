import React, { useEffect, useState } from 'react';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../shared/components/CustomTable';
import { useTranslation } from 'react-i18next';
import { deleteStaff, getUsers } from '../../services/AccountServices';
import Breadcrumb from '../../shared/components/BreadCrumb/BreadCrumb';
import UserManagementModal from './UserManagementModal';
import Delete from '../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';

interface UserManagementProps {
  isEditable: boolean;
}

interface Staff {
  employeeId: string;
}

const UserManagement: React.FC<UserManagementProps> = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);

  const breadcrumbPaths = [
    { label: 'Dashboard', path: '/dashboard' },
    { label: 'Settings', path: '/dashboard/' },
    { label: 'User Management', path: '/settings/users' },
  ];

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      if (response) {
        setUsers(response);
        if (response?.totalRecords) {
          setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
        }
      }
    } catch (error) {
      toast.error(t('errorFetchingUsers', 'Error fetching users'));
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, itemsPerPage]);

  const openModal = (staff: any) => {
    setSelectedStaff(staff);
    setIsOpenModal(true);
  };

  const deleteSelectedStaff = async (staff: Staff | null) => {
    if (staff) {
      const response = await deleteStaff(staff.employeeId);
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        fetchUsers();
      } else {
        toast.error(response?.data?.errorMessage);
      }
    }

    setIsDeleteModalOpen(false);
  };

  const handleDeleteMOdal = (staff: any) => {
    setSelectedStaff(staff);
    setIsDeleteModalOpen(true);
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const columns: Column[] = [
    {
      title: t('employeeId', 'Employee ID'),
      dataKey: 'employeeId',
      type: 'string',
    },
    { title: t('fullName', 'Full Name'), dataKey: 'fullName', type: 'string' },
    {
      title: t('phoneNumber', 'Phone Number'),
      dataKey: 'phoneNumber',
      type: 'string',
    },
    {
      title: t('department', 'Department'),
      dataKey: 'department',
      type: 'string',
    },
    { title: t('email', 'Email'), dataKey: 'email', type: 'string' },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (staff: any) => openModal(staff),
          status: false,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (staff: any) => openModal(staff),
          status: true,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (staff: any) => handleDeleteMOdal(staff),
          status: true,
        },
      ],
    },
  ];

  return (
    <div>
      <Breadcrumb paths={breadcrumbPaths} />
      <CustomTable
        data={users}
        columns={columns}
        pageCount={pageCount}
        showAddButton={true}
        addButtonText={t('addUser', 'Add User')}
        onAddButtonClick={() => setIsOpenModal(true)}
        title={t('userManagement', 'User Management')}
        showTitle={true}
        showPagination={true}
        showSearch={true}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />

      {isOpenModal && (
        <UserManagementModal
          onSubmit={() => {
            setIsOpenModal(false);
            setSelectedStaff(null);
            fetchUsers();
          }}
          onClose={() => {
            setIsOpenModal(false);
            setSelectedStaff(null);
          }}
          staff={selectedStaff}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t(
            'deleteWarning',
            'This action is permanent, all data would be lost.'
          )}
          onDelete={() => deleteSelectedStaff(selectedStaff)}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};

export default UserManagement;
