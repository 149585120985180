import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getTaskHistory, processTask } from '../../services/workflowServices';
import { setSupplierStatus } from '../../services/supplierService';
import { OnboardingStatus } from '../SupplierManagement/SupplierManagement';

interface ReviewModalProps {
  isOpen: boolean;
  isTerminatingStep: boolean;
  requestId: string;
  userId: string;
  entityName: string;
  entityId: string;
  onClose: () => void;
  onSubmit: () => void;
}

enum WorkflowActionType {
  Approved,
  Rejected,
  Canceled,
  InProgress,
}

interface StageProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  content: React.ReactNode;
}

const Stage: React.FC<StageProps> = ({ title, isOpen, onToggle, content }) => (
  <div className="border-b border-gray-200 mb-4">
    <h4
      className="text-gray-600 flex items-center font-medium cursor-pointer"
      onClick={onToggle}
    >
      {title}
      {isOpen ? (
        <FaChevronUp className="ml-2" />
      ) : (
        <FaChevronDown className="ml-2" />
      )}
    </h4>
    {isOpen && <div className="mt-2">{content}</div>}
  </div>
);

const ReviewModal: React.FC<ReviewModalProps> = ({
  isOpen,
  isTerminatingStep,
  requestId,
  userId,
  entityName,
  entityId,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [taskHistoryData, setTaskHistoryData] = useState<any[]>([]);
  const [remarks, setRemarks] = useState<string>('');

  // State for toggling stages
  const [openStages, setOpenStages] = useState<{ [key: string]: boolean }>({
    stage1: false,
    stage2: false,
    stage3: false,
    stage4: false,
  });
  const toggleStage = (stage: string) => {
    setOpenStages((prevState) => ({
      ...prevState,
      [stage]: !prevState[stage],
    }));
  };

  const handleGetTaskHistory = async () => {
    const response = await getTaskHistory(requestId);
    if(response?.isSuccess) {
      setTaskHistoryData(response?.data);
    }else {
      toast.error(response?.errorMessage);
    }
  }

  useEffect(() => {
    if (requestId) {
      handleGetTaskHistory();
    }
  }, [requestId]);

  const handleSubmit = async (action: WorkflowActionType) => {
    if (remarks === '') toast.error('Please enter remarks');
    const payload = {
      requestId,
      entityId,
      entityName,
      comment: remarks,
      workflowAction: action,
      approverId: userId,
      descriptions: '',
    };
    const response = await processTask(payload);
    if (response?.isSuccess) {
      const status =
        action === WorkflowActionType.Rejected
          ? OnboardingStatus.Rejected
          : action === WorkflowActionType.Approved && isTerminatingStep
          ? OnboardingStatus.Approved
          : OnboardingStatus.Processing;
      const setStatusResponse = await setSupplierStatus(entityId, status);
      if(setStatusResponse?.isSuccess) {
        toast.success('Task processed successfully');
        onSubmit();
      }else {
        toast.error(setStatusResponse?.errorMessage);
      }
    } else {
      toast.error(response?.errorMessage);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-2xl shadow-lg w-full max-w-5xl p-8 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold mb-4">
            {t('reviewApplication', 'Review Application')}
          </h2>
          <button className="text-gray-500 text-xl" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        <div className=" w-[100%] flex gap-4">
          {/* Form Section */}
          <div className="space-y-12 w-[70%]">
            {/* <div>
              <label className="block text-gray-700 font-medium mb-1">
                {t('selectActionToPerform', 'Select Action to Perform')}
              </label>
              <select
                value={action}
                onChange={(e) => setAction(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2"
              >
                <option value="Approve">{t('approve', 'Approve')}</option>
                <option value="Reject">{t('reject', 'Reject')}</option>
                <option value="Request for Info">
                  {t('requestForInfo', 'Request for Info')}
                </option>
              </select>
            </div> */}

            <div>
              <label className="block text-gray-700 font-medium mb-1">
                {t('commentsRemarks', 'Comments/Remarks')}
              </label>
              <textarea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder={t('enterRemarks', 'Enter Remarks')}
                className="w-full p-3 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                rows={6}
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between mt-6">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-8 rounded-lg"
                onClick={onClose}
              >
                {t('cancel', 'Cancel')}
              </button>
              <div className="flex justify-end space-x-4">
                <button
                  className="bg-error hover:bg-error-700 text-white font-semibold py-2 px-8 rounded-lg"
                  onClick={() => handleSubmit(WorkflowActionType.Rejected)}
                >
                  {t('reject', 'Reject')}
                </button>
                <button
                  className="bg-[#20b841] hover:bg-green-700 text-white font-semibold py-2 px-6 rounded-lg"
                  onClick={() => handleSubmit(WorkflowActionType.Approved)}
                >
                  {isTerminatingStep
                    ? t('finalApprover', 'Final Approver')
                    : t('forwardToNextReviewer', 'Forward to next Reviewer')}
                </button>
              </div>
            </div>
          </div>

          {/* Sidebar for Application History */}
          <div className="overflow-y-auto max-h-[400px]  bg-white p-4   rounded-md">
            <h3 className="font-semibold text-lg mb-4">
              {t('applicationHistory', 'Application History')}
            </h3>

            {taskHistoryData.map((task, index) => (
              <Stage
                key={index}
                title={`Stage ${index + 1} - ${task.workflowLevel}`}
                isOpen={openStages[`stage${index + 1}`]}
                onToggle={() => toggleStage(`stage${index + 1}`)}
                content={
                  <>
                    <p className={`text-sm text-${task.workflowAction === WorkflowActionType.Approved ? 'green' : 'red'}-600`}>{task.processedBy}</p>
                    <p className="text-gray-500 text-sm">
                        <span className={`text-${task.workflowAction === WorkflowActionType.Approved ? 'green' : 'red'}-600`}>
                        {task.workflowAction === WorkflowActionType.Approved ? '✔ Approved' : '✖ Rejected'}:
                        </span>{' '}
                        {new Date(task.modifiedOn).toLocaleDateString('en-US', {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        })}
                    </p>
                    <p className="text-sm text-gray-700">{task.comment}</p>
                  </>
                }
              />
            ))}

            {/* <Stage
              title={t('stage3FinalApproval', 'Stage 3 - Final Approval')}
              isOpen={openStages.stage3}
              onToggle={() => toggleStage('stage3')}
              content={
                <>
                  <p className="text-green-500 text-sm">
                    {t('johnSmithFinalReviewer', 'John Smith (Final Reviewer)')}
                  </p>
                  <p className="text-gray-500 text-sm">
                    <span className="text-green-600">
                      {t('approved', '✔ Approved')}:
                    </span>{' '}
                    {t('monJune122023', 'Mon, June 12, 2023')}
                  </p>
                  <p className="text-sm text-gray-700">
                    {t('finalApprovalCompleted', 'Final approval completed.')}
                  </p>
                </>
              }
            /> */}

            {/* <Stage
              title={t('stage4Archival', 'Stage 4 - Archival')}
              isOpen={openStages.stage4}
              onToggle={() => toggleStage('stage4')}
              content={
                <>
                  <p className="text-gray-500 text-sm">
                    <span className="text-gray-600">
                      {t('archived', '✔ Archived')}:
                    </span>{' '}
                    {t('tuesJuly152023', 'Tues, July 15, 2023')}
                  </p>
                  <p className="text-sm text-gray-700">
                    {t(
                      'applicationArchived',
                      'Application archived for future reference.'
                    )}
                  </p>
                </>
              }
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
