// import React, { useEffect, useState } from 'react';

// import { MenuItem, menuItems } from './sidebarData';
// import './Sidebar.css';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useGlobalContext } from '../../../core/context/GlobalContext';

// const Sidebar: React.FC = () => {
//   const [selected, setSelected] = useState<number>(1); // Default selected to 'Home'
//   const navigate = useNavigate();
//   const { logout } = useGlobalContext();

//   const handleSelect = (id: number, link: string) => {
//     setSelected(id);
//     navigate(`${link}`);
//   };

//   const location = useLocation(); // Get the current route

//   // Sync the selected state with the current URL on component mount
//   useEffect(() => {
//     const currentPath = location.pathname;
//     const currentItem = menuItems.find((item) => item.link === currentPath);

//     if (currentItem) {
//       setSelected(currentItem.id); // Update selected based on the current route
//     }
//   }, [location.pathname]); // Re-run this effect whenever the path changes

//   return (
//     <div className="sidebar-container ">
//       <div className="sidebar-content">
//         <h2 className="mb-2">All ESSC modules</h2>
//         <ul className="menu-list">
//           {menuItems.map((item: MenuItem) => (
//             <li
//               key={item.id}
//               className={`menu-item ${selected === item.id ? 'selected' : ''}`}
//               onClick={() => handleSelect(item.id, item.link)}
//             >
//               <img src={item.icon} alt={item.name} />
//               {<span className=" text-[14px]">{item.name}</span>}
//             </li>
//           ))}
//         </ul>
//       </div>

//       <div className="sidebar-footer p-4">
//         <ul className="menu-list">
//           <li className="menu-item flex items-center space-x-2 cursor-pointer" onClick={logout}>
//             <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`} alt="Logout" className="w-7 h-7" />
//             <span className="text-2xl">Logout</span>
//           </li>
//        </ul>
//        <div className="border-t my-4"></div>
//        <p className="text-xs text-gray-500">&copy; 2024 Your Company. All rights reserved.</p>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useEffect, useState } from 'react';
import { MenuItem, menuItems } from './sidebarData';
import './Sidebar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { myMenu } from '../../../services/AccountServices';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'; // Icons for collapsible menus
import { toast } from 'react-toastify';
import { getUserRoleFromLocalStorage } from '../../../services/utilServices';

const Sidebar: React.FC = () => {
  const [selected, setSelected] = useState<number>(1);
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItem[]>([]);
  const [adminUser] = useState<boolean>(false); // assuming admin for now
  const [expandedItems, setExpandedItems] = useState<number[]>([]); // Track expanded/collapsed menu items
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useGlobalContext();

  const handleSelect = (id: number, link: string) => {
    setSelected(id);
    navigate(`${link}`);
  };

  const toggleExpand = (id: number) => {
    setExpandedItems((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  // useEffect(() => {
  //   const currentPath = location.pathname;
  //   const currentItem = menuItems.find((item) => item.link === currentPath);

  //   if (currentItem) {
  //     setSelected(currentItem.id);
  //   }
  // }, [location.pathname]);

  const mapMenuItems = (menus: any[]) => {
    const menuMap: { [key: string]: MenuItem } = {};
    const rootMenus: MenuItem[] = [];

    // Create a mapping of route names to icons from the static menu
    const iconMap: { [key: string]: string } = {};
    menuItems.forEach((item) => {
      iconMap[item.link] = item.icon;
      if (item.children) {
        item.children.forEach((child) => {
          iconMap[child.link] = child.icon;
        });
      }
    });

    menus.forEach((menu) => {
      const menuItem: MenuItem = {
        id: menu.menuId,
        name: menu.menuName,
        link: `/${menu.menuUrl}`,
        icon: iconMap[`/${menu.menuUrl}`] || '',
        children: [],
      };

      menuMap[menu.menuId] = menuItem;

      if (menu.parentMenu) {
        const parentMenu = menuMap[menu.parentMenu.menuId];
        if (parentMenu) {
          if (parentMenu.children) {
            parentMenu.children.push(menuItem);
          } else {
            parentMenu.children = [menuItem];
          }
        } else {
          menuMap[menu.parentMenu.menuId] = {
            id: menu.parentMenu.menuId,
            name: menu.parentMenu.menuName,
            link: `/${menu.parentMenu.menuUrl}`,
            icon: iconMap[`/${menu.parentMenu.menuUrl}`] || '',
            children: [menuItem],
          };
        }
      } else {
        rootMenus.push(menuItem);
      }
    });

    // Create a mapping of static menu links to their indices
    const staticMenuOrder: { [key: string]: number } = {};
    menuItems.forEach((item, index) => {
      staticMenuOrder[item.link] = index;
      if (item.children) {
        item.children.forEach((child, childIndex) => {
          staticMenuOrder[child.link] = index + childIndex / 100; // Ensure children are ordered within their parent
        });
      }
    });

    // Sort the rootMenus based on the static menu order
    rootMenus.sort(
      (a, b) => (staticMenuOrder[a.link] || 0) - (staticMenuOrder[b.link] || 0)
    );

    // Sort the children of each root menu item
    rootMenus.forEach((menuItem) => {
      if (menuItem.children && menuItem.children.length > 0) {
        menuItem.children.sort(
          (a, b) =>
            (staticMenuOrder[a.link] || 0) - (staticMenuOrder[b.link] || 0)
        );
      }
    });

    return rootMenus;
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        if (adminUser) {
          setFilteredMenuItems(menuItems);
          return;
        }
        const response = await myMenu();
        if (response.isSuccess) {
          const mappedMenus = mapMenuItems(response.data);
          setFilteredMenuItems(mappedMenus);
        } else {
          const userRole = getUserRoleFromLocalStorage();
          if (userRole === 'Supplier') {
            setFilteredMenuItems(menuItems.filter((item) => item.id === 1 || item.id === 2));
          } else {
            setFilteredMenuItems(menuItems.filter((item) => item.id === 1)); // Set to Home menu
          }
        }
      } catch (error) {
        toast.error('Error fetching menu data:');
      }
    };

    fetchMenuData();
  }, [adminUser]);

  const renderMenuItems = (items: MenuItem[]) => {
    return items.map((item) => {
      const isExpanded = expandedItems.includes(item.id);
      const hasChildren = item.children && item.children.length > 0;

      return (
        <li
          key={item.id}
          className={`menu-item ${!hasChildren && selected === item.id ? 'selected' : ''}`}
          onClick={() => !hasChildren && handleSelect(item.id, item.link)}
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={(e) => {
              toggleExpand(item.id);
            }}
          >
            <div className="flex items-center space-x-2">
              <img src={item.icon} alt={item.name} className="w-7 h-7" />
              <span className="text-2xl">{item.name}</span>
            </div>
            {hasChildren && (
              <button className="focus:outline-none">
                {isExpanded ? (
                  <ChevronDownIcon className="w-7 h-7 text-gray-500" />
                ) : (
                  <ChevronRightIcon className="w-7 h-7 text-gray-500" />
                )}
              </button>
            )}
          </div>
          {hasChildren && isExpanded && (
            <ul className="pl-8 space-y-1 mt-4">
              {item.children && renderMenuItems(item.children)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-content">
        <h2 className="text-lg font-semibold mb-4">All ESSC modules</h2>
        <ul className="menu-list space-y-1">
          {renderMenuItems(filteredMenuItems)}
        </ul>
      </div>

      <div className="sidebar-footer p-4">
        <ul className="menu-list">
          <li
            className="menu-item flex items-center space-x-2 cursor-pointer"
            onClick={logout}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`}
              alt="Logout"
              className="w-7 h-7"
            />
            <span className="text-2xl">Logout</span>
          </li>
        </ul>
        <div className="border-t my-4"></div>
        <p className="text-xs text-gray-500">
          &copy; 2024 Your Company. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
