import React, { useState } from 'react';
import Module from '../Module';
import { operatorModulesData } from '../index';
import ProgressBar from '../../../shared/components/ProgressBar/ProgressBar';
import ReviewModal from '../../OperatorManagement/ReviewAppModal';
import DocumentRequestModal from './RequestAddDoc';

const OperatorModule: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true); // State to control the modal visibility

  const onClose = () => {
    setIsOpen(false); // Close the modal
  };
  return (
    <div className="">
      <Module
        title="Operator Module"
        desc="This is the Operator Module."
        modulesData={operatorModulesData}
      />
      <ProgressBar />
      {/* {isOpen && (
        <ReviewModal isOpen={isOpen} onClose={onClose} onSubmit={() => {}} />
      )} */}
      {/* {isOpen && <DocumentRequestModal isOpen={isOpen} onClose={onClose} />} */}
    </div>
  );
};

export default OperatorModule;
