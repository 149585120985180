import React, { useEffect, useState } from 'react';
import AwardModal from './AwardModal';
import ProjectModal from './ProjectModal';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  deleteAward,
  deleteProject,
  getAwards,
  getProjects,
} from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';
import CustomTable, { Column } from '../../../shared/components/CustomTable';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface TableProps {
  isEditable: boolean;
}

export interface ProjectInterface {
  projectId: string;
  projectName: string;
  projectValue: number;
  yearOfExecution: number;
  companyId: string;
  clientName: string;
}

export interface AwardInterface {
  awardId: string;
  awardName: string;
  issuedBy: string;
  issuedDate: string;
}

export const ProjectAndAwardTable: React.FC<TableProps> = ({ isEditable }) => {
  const { t } = useTranslation();
  const [isAwardModalOpen, setIsAwardModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAwardDeleteModalOpen, setIsAwardDeleteModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] =
    useState<ProjectInterface | null>(null);
  const [selectedAward, setSelectedAward] = useState<AwardInterface | null>(
    null
  );
  const [projects, setProjects] = useState<ProjectInterface[]>([]);
  const [awards, setAwards] = useState<AwardInterface[]>([]);
  const { supplierId } = useGlobalContext();

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId');

  const fetchProjectData = async () => {
    const fetchedId = isSupplier ? supplierId : companyId;
    if (!fetchedId) return;
    const response = await getProjects(fetchedId, {
      pageNumber: 1,
      pageSize: 10,
    });
    if (response) setProjects(response.data);
  };

  const fetchAwardData = async () => {
    const fetchedId = isSupplier ? supplierId : companyId;
    if (!fetchedId) return;
    const response = await getAwards(fetchedId, {
      pageNumber: 1,
      pageSize: 10,
    });
    if (response) setAwards(response.data);
  };

  const deleteProjectData = async () => {
    if (!selectedProject) return;
    const { projectId } = selectedProject;
    const response: any = await deleteProject(projectId);
    if (response.isSuccess) {
      toast.success(
        t('projectDeletedSuccessfully', 'Project deleted successfully')
      );
      setIsDeleteModalOpen(false);
      fetchProjectData();
    }
  };

  const deleteAwardData = async () => {
    if (!selectedAward) return;
    const { awardId } = selectedAward;
    const response: any = await deleteAward(awardId);
    if (response.isSuccess) {
      toast.success(
        t('awardDeletedSuccessfully', 'Award deleted successfully')
      );
      setIsAwardDeleteModalOpen(false);
      fetchAwardData();
    }
  };

  function openProjectModal() {
    setIsProjectModalOpen(true);
    setSelectedProject(null);
  }

  const openAwardModal = () => {
    setIsAwardModalOpen(true);
    setSelectedAward(null);
  };

  const editProject = (project: ProjectInterface) => {
    setSelectedProject(project);
    setIsProjectModalOpen(true);
  };

  const editAward = (award: AwardInterface) => {
    setSelectedAward(award);
    setIsAwardModalOpen(true);
  };

  const deleteProjectModal = (project: ProjectInterface) => {
    setSelectedProject(project);
    setIsDeleteModalOpen(true);
  };

  const deleteAwardModal = (award: AwardInterface) => {
    setSelectedAward(award);
    setIsAwardDeleteModalOpen(true);
  };

  const closeProjectModal = () => {
    setIsProjectModalOpen(false);
    setSelectedProject(null);
    fetchProjectData();
  };

  const closeAwardModal = () => {
    setIsAwardModalOpen(false);
    setSelectedAward(null);
    fetchAwardData();
  };

  const closeProjectDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedProject(null);
    fetchAwardData();
  };

  const closeAwardDeleteModal = () => {
    setIsAwardDeleteModalOpen(false);
    setSelectedAward(null);
  };

  useEffect(() => {
    fetchAwardData();
    fetchProjectData();
  }, []);

  const projectColumns: Column[] = [
    { title: t('name', 'Name'), dataKey: 'projectName', type: 'string' },
    {
      title: t('clientName', 'Client Name'),
      dataKey: 'clientName',
      type: 'string',
    },
    {
      title: t('yearOfExecution', 'Year of Execution'),
      dataKey: 'yearOfExecution',
      type: 'number',
    },
    {
      title: t('projectValue', 'Project Value'),
      dataKey: 'projectValue',
      type: 'number',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: ProjectInterface) => editProject(item),
          status: !isEditable,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: ProjectInterface) => editProject(item),
          status: isEditable,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: ProjectInterface) => deleteProjectModal(item),
          status: isEditable,
        },
      ],
    },
  ];

  const awardColumns: Column[] = [
    {
      title: t('titleOfAward', 'Title of Award'),
      dataKey: 'awardName',
      type: 'string',
    },
    {
      title: t('issuingBody', 'Issuing Body'),
      dataKey: 'issuedBy',
      type: 'string',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: AwardInterface) => editAward(item),
          status: !isEditable,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: AwardInterface) => editAward(item),
          status: isEditable,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: AwardInterface) => deleteAwardModal(item),
          status: isEditable,
        },
      ],
    },
  ];

  return (
    <div>
      <div className="p-4">
        <h2 className="text-xl font-bold">{t('projects', 'Projects')}*</h2>

        <CustomTable
          data={projects}
          columns={projectColumns}
          title={t('projects', 'Projects')}
          showTitle={false}
          showPagination={true}
          showAddButton={isEditable}
          showSearch={true}
          addButtonText={t('add', '+ Add')}
          onAddButtonClick={openProjectModal}
        />

        {isProjectModalOpen && (
          <ProjectModal
            onClose={closeProjectModal}
            initialValues={selectedProject}
            isEditable={isEditable}
          />
        )}

        {isDeleteModalOpen && (
          <Delete
            title={t('areYouSure', 'Are you sure')}
            desc={t(
              'permanentActionWarning',
              'This action is permanent, all data would be lost.'
            )}
            onDelete={deleteProjectData}
            onCancel={closeProjectDeleteModal}
          />
        )}
      </div>

      <div className="p-4">
        <h2 className="text-xl font-bold">{t('awards', 'Awards')}*</h2>

        <CustomTable
          data={awards}
          columns={awardColumns}
          title={t('awards', 'Awards')}
          showTitle={false}
          showPagination={true}
          showSearch={true}
          showAddButton={isEditable}
          addButtonText={t('add', '+ Add')}
          onAddButtonClick={openAwardModal}
        />

        {isAwardModalOpen && (
          <AwardModal
            isEditable={isEditable}
            onClose={closeAwardModal}
            initialValues={selectedAward}
          />
        )}

        {isAwardDeleteModalOpen && (
          <Delete
            title={t('areYouSure', 'Are you sure')}
            desc={t(
              'permanentActionWarning',
              'This action is permanent, all data would be lost.'
            )}
            onDelete={deleteAwardData}
            onCancel={closeAwardDeleteModal}
          />
        )}
      </div>
    </div>
  );
};
