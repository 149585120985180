import React, { useState } from 'react';
import AppButton from '../../components/AppButton/AppButton'; // Assuming you have an AppButton component

interface DeleteProps {
  title: string;
  desc: string;
  onDelete: () => void;
  onCancel: () => void;
}

const Delete: React.FC<DeleteProps> = ({ title, desc, onDelete, onCancel }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-12 rounded-2xl shadow-lg max-w-xl w-full flex flex-col justify-center items-center">
            <h1 className="text-3xl flex justify-center items-center font-bold mb-4">
              {title}
            </h1>
            <p className="text-xl text-gray-600 mb-6 flex justify-center w-full">
              {desc}
            </p>
            <p className="text-xl text-gray-600 mb-6 flex justify-center w-full">
              Do you want to continue?
            </p>
            <div className="flex gap-4">
              <AppButton
                title="Cancel"
                onClick={() => {
                  setIsOpen(false);
                  onCancel();
                }}
                className="bg-gray-300 text-gray-700 px-12 h-16 py-2 rounded-lg font-semibold"
              />
              <AppButton
                title="Delete"
                onClick={() => {
                  setIsOpen(false);
                  onDelete();
                }}
                className="bg-primary text-black px-12 h-16 py-2 rounded-lg font-semibold"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Delete;
