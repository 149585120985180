import React, { useState, useEffect } from 'react';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../shared/components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import Delete from '../../shared/utils/Delete/Delete';
import Breadcrumb from '../../shared/components/BreadCrumb/BreadCrumb';
import NavigationMenuModal from './NavigationMenuModal';
import { deleteMenu, getAllMenu } from '../../services/AccountServices'; // Import the API functions

interface NavigationMenu {
  menuId: string;
  menuName: string;
  description: string;
}

const breadcrumbPaths = [
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Settings', path: '/settings' },
  { label: 'Navigation Menu', path: '/settings/menu' },
];

const NavigationMenu = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [menus, setMenus] = useState<NavigationMenu[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<NavigationMenu | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling the modal
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode

  const navigate = useNavigate();
  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  // Fetch menus data from the API
  const fetchMenusData = async () => {
    try {
      const response = await getAllMenu(pageRequest);
      if (response?.data) {
        setMenus(response.data);
        if (response?.totalRecords) {
          setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
        }
      }
    } catch (error) {
      // console.error('Error fetching menus:', error);
    }
  };

  useEffect(() => {
    fetchMenusData();
  }, [currentPage, itemsPerPage]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // Implement search logic here if needed
  };

  const handleDelete = (item: NavigationMenu) => {
    setSelectedMenu(item);
    setIsDeleteModalOpen(true);
  };

  const deleteMenuItem = async () => {
    if (selectedMenu) {
      try {
        const response = await deleteMenu(selectedMenu.menuId);
        if (response?.isSuccess) {
          fetchMenusData();
        }
        setIsDeleteModalOpen(false);
        setSelectedMenu(null);
      } catch (error) {
        // console.error('Error deleting menu:', error);
      }
    }
  };

  const handleAddMenu = () => {
    setIsEditMode(false);
    setIsModalOpen(true); // Open modal when "Add" is clicked
  };

  const handleEditMenu = (item: NavigationMenu) => {
    setSelectedMenu(item);
    setIsEditMode(true);
    setIsModalOpen(true); // Open modal when "Edit" is clicked
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedMenu(null); // Clear selected menu
    fetchMenusData();
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const columns: Column[] = [
    {
      title: 'ID',
      dataKey: 'menuId',
      type: 'string',
    },
    {
      title: 'Menu Name',
      dataKey: 'menuName',
      type: 'string',
    },
    {
      title: 'Url',
      dataKey: 'menuUrl',
      type: 'string',
    },
    {
      title: 'Actions',
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: 'Edit',
          className: 'text-green-500',
          action: handleEditMenu,
          status: true,
        },
        {
          label: 'Delete',
          className: 'text-red-500',
          action: handleDelete,
          status: true,
        },
      ],
    },
  ];

  return (
    <div className="px-[32px] py-6">
      <h1 className="text-4xl font-bold mb-4">Navigation Menu</h1>
      <Breadcrumb paths={breadcrumbPaths} />

      <CustomTable
        data={menus}
        columns={columns}
        pageCount={pageCount}
        showTitle={false}
        addButtonText="+ Add"
        showPagination={true}
        showSearch={true}
        onSearch={handleSearch}
        showAddButton={true}
        title="Add Navigation Menu"
        onAddButtonClick={handleAddMenu}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure?"
          desc="This action is permanent, all data would be lost."
          onDelete={deleteMenuItem}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}

      {isModalOpen && (
        <NavigationMenuModal
          onClose={handleModalClose}
          isEditable={isEditMode}
          menu={selectedMenu}
        />
      )}
    </div>
  );
};

export default NavigationMenu;
