import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ModuleCard.css';
interface CardProps {
  imageSrc: string;
  moduleTitle: string;
  moduleType: string;
  link: string;
  external: boolean;
}

const ModuleCard: React.FC<CardProps> = ({
  imageSrc,
  moduleTitle,
  moduleType,
  link,
  external,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (external) {
      window.open(link, '_blank', 'noopener,noreferrer'); // Open in new tab
    } else {
      navigate(link); // Navigate within the app
    }
  };

  return (
    <div className="choose-demo-card " onClick={handleClick}>
      <img
        src={imageSrc}
        alt={moduleTitle}
        className="w-full h-10 object-cover rounded-md"
      />
      <div className="flex flex-col justify-start items-start">
        <p className="text-left text-[20px]  font-medium mt-4">{moduleTitle}</p>
        <p className="text-left text-[14px]  font-bold text-gray-500">
          {moduleType}
        </p>
      </div>
    </div>
  );
};

export default ModuleCard;
