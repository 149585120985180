import React, { useEffect, useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { FaSort } from 'react-icons/fa'; // Sort icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // 3-dots icon
import ReactPaginate from 'react-paginate';
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import ParameterModal, { WorkflowParameterType } from './ParameterModal'; // Importing the modal
import { useLocation, useNavigate } from 'react-router-dom';
import {
  deleteParameter,
  getParameters,
} from '../../services/workflowServices';
import { toast } from 'react-toastify';
import Delete from '../../shared/utils/Delete/Delete';
import { workflowParameterTypeToString } from '../../services/utilServices';
import CustomTable, { Column } from '../../shared/components/CustomTable';
import { useTranslation } from 'react-i18next';

type ParameterItem = {
  id: number;
  parameterName: string;
  parameterType: string;
};

interface PageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId: string;
}

export interface ParameterData {
  workflowParameterId: string;
  parameterName: string;
  parameterType: number;
  workflowId: string;
}

const ParameterConfig: React.FC = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 2;
  const [selectedAll, setSelectedAll] = useState(false);
  const [parameterData, setParameterData] = useState<ParameterData[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [parameterItem, setParameterItem] = useState<ParameterData | null>(
    null
  );

  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    parameterData.map(() => false)
  );
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const navigate = useNavigate();

  const location = useLocation();
  const workflowId = location.state?.workflowId;
  const pageRequest: PageRequest = {
    pageNumber: 1,
    pageSize: 10,
    workflowId: workflowId,
  };

  const columns: Column[] = [
    { title: t('ID', 'ID'), dataKey: 'workflowParameterId', type: 'string' },
    {
      title: t('Parameter Name', 'Parameter Name'),
      dataKey: 'parameterName',
      type: 'string',
    },
    {
      title: t('Parameter Type', 'Parameter Type'),
      dataKey: 'parameterType',
      type: 'parameterType',
    },
    {
      title: t('Actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('Edit', 'Edit'),
          className: 'text-green-500',
          action: (item: any) => handleEdit(item),
          status: true,
        },
        {
          label: t('Delete', 'Delete'),
          className: 'text-red-500',
          action: (item: any) => handleDelete(item),
          status: true,
        },
      ],
    },
  ];

  const handleEdit = (item: any) => {
    editModal(item);
  };

  const handleDelete = (item: any) => {
    deleteModal(item);
  };

  const toggleSelectAll = () => {
    const newSelected = !selectedAll;
    setSelectedAll(newSelected);
    setSelectedItems(parameterData.map(() => newSelected));
  };

  const toggleCheckbox = (index: number) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  const editModal = (parameter: ParameterData) => {
    setParameterItem(parameter);
    setModalOpen(true);
  };

  const deleteModal = (parameter: ParameterData) => {
    setParameterItem(parameter);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedCommand = async () => {
    if (!parameterItem) return;
    const { workflowParameterId } = parameterItem;
    const response: any = await deleteParameter(workflowParameterId);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      setIsDeleteModalOpen(false);
      fetchCommandList();
    }
  };

  const closeEditModal = () => {
    setModalOpen(false);
    setParameterItem(null);
    fetchCommandList();
  };

  const handleAddButtonClick = () => {
    setModalOpen(true);
  };

  const fetchCommandList = async () => {
    try {
      const response = await getParameters(pageRequest);
      if (response) {
        const parameterData = response.data.map((parameter: any) => ({
          ...parameter,
          parameterType: convertParameterType(parameter.parameterType),
        }));
        setParameterData(parameterData);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const convertParameterType = (
    parameterType: string
  ): WorkflowParameterType => {
    switch (parameterType) {
      case 'Int':
        return WorkflowParameterType.Int;
      case 'String':
        return WorkflowParameterType.String;
      case 'List':
        return WorkflowParameterType.List;
      case 'Guid':
        return WorkflowParameterType.Guid;
      default:
        return WorkflowParameterType.String; // Default to String if unknown type
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  useEffect(() => {
    fetchCommandList();
  }, []);

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const filteredData = parameterData.filter((item) =>
    item.parameterName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <div className="p-4 flex-1">
        {/* Title and Add button */}
        <div className="flex justify-start">
          <button
            type="button"
            onClick={goBack}
            className="text-black px-4 py-2 my-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
          >
            {t('Back', 'Back')}
          </button>
        </div>

        <CustomTable
          data={currentItems}
          columns={columns}
          pageCount={pageCount} // Pass the total page count
          showPagination={true} // Control whether to show pagination
          showSearch={true} // Control whether to show the search box
          onSearch={handleSearch} // Pass the search handler
          showAddButton={true}
          addButtonText={t('+ New Parameter', '+ New Parameter')}
          title={t('Parameter Configuration', 'Parameter Configuration')}
          onAddButtonClick={handleAddButtonClick}
        />

        {/* Modal for adding new parameters */}
        {isModalOpen && (
          <ParameterModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            onEdit={closeEditModal}
            workflowId={workflowId}
            initialValues={parameterItem}
          />
        )}

        {isDeleteModalOpen && (
          <Delete
            title={t('Are you sure', 'Are you sure')}
            desc={t(
              'This action is permanent, all data would be lost.',
              'This action is permanent, all data would be lost.'
            )}
            onDelete={deleteSelectedCommand}
            onCancel={() => setModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default ParameterConfig;
