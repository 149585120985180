import React from 'react';
import { IconType } from 'react-icons';
import { useTranslation } from 'react-i18next';

interface StatCardProps {
  title: string;
  value: number;
  icon: IconType;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon: Icon }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white gap-4 p-6 rounded shadow flex items-center">
      <div>
        <Icon size={30} className="text-3xl mr-4 text-gray-600" />
      </div>
      <div className="flex flex-col items-start">
        <h3 className="text-2xl font-semibold">{value}</h3>
        <p className="text-gray-600">{t(title)}</p>
      </div>
    </div>
  );
};

export default StatCard;
