import { Endpoint } from '../endpoint/endpoint';
import axiosInstance from '../axiosInstance';
import { toast } from 'react-toastify';

interface ProcurementProject {
  procurementProjectId: string;
  projectTitle: string;
  projectDescription: string;
  prerequisites: string;
  status: number;
  lastUpdated: string;
  additionalInformation: string;
  tags: { tagName: string }[];
  industryId: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  projectGoodsAndServices: any[];
  projectDocuments: any[];
  referenceToWork: string;
  assignPointsToReferenceToWork: boolean;
  approvedDate: Date | null;
  biddingStartDate: Date;
  biddingEndDate: Date;
  bidRequirement: string;
}

interface ApiResponse {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  data: ProcurementProject[];
}

export const getAProcurement = async (projectId: string) => {
  try {
    const response = await axiosInstance.get(
      `${Endpoint.procurement.getOne}/${projectId}`
    );
    return response.data;
  } catch {
    //return
  }
};

export const createAProcurement = async (updatedFormData: any) => {
  try {
    const response = await axiosInstance.post(
      Endpoint.procurement.create,
      updatedFormData
    );
    return response.data;
  } catch {
    //return
  }
};

export const editAProcurement = async (updatedFormData: any) => {
  try {
    const response = await axiosInstance.post(
      Endpoint.procurement.update,
      updatedFormData
    );
    return response.data;
  } catch {
    //return
  }
};

export const getAllProcurements = async (
  statusMap: any,
  activeStatus: any
): Promise<ApiResponse> => {
  const response = await axiosInstance.get<ApiResponse>(
    `${Endpoint.procurement.get}`,
    {
      params: {
        status: statusMap[activeStatus as keyof typeof statusMap],
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const UnpublishAProcurement = async (projectId: string) => {
  try {
    const response = await axiosInstance.put(
      `${Endpoint.procurement.unpublishProcurement}`,
      {
        procurementProjectId: projectId,
      }
    );
    return response.data;
  } catch {
    //return
  }
};

export const getIndustries = async () => {
  try {
    const response = await axiosInstance.get(
      `${Endpoint.procurement.getIndustries}`
    );
    return response.data;
  } catch {
    //return
  }
};

export const getSupplierTypes = async () => {
  try {
    const response = await axiosInstance.get(
      `${Endpoint.procurement.getSupplierTypes}`
    );
    return response.data;
  } catch {
    //return
  }
};

export const getTags = async () => {
  try {
    const response = await axiosInstance.get(
      `${Endpoint.procurement.getSuppliers}`
    );
    return response.data;
  } catch {
    //return
  }
};
