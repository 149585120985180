import React from 'react';
import { useTranslation } from 'react-i18next';
import './ChooseDemo.css';

const ChooseDemo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="choose-demo-container p-6 bg-gray-100">
      <div className="mb-10 px-4 py-2">
        <h1 className="text-3xl font-semibold text-gray-800">
          {t('chooseDemo.title', 'Choose Demo')}
        </h1>
        <p className="text-sm text-gray-500">
          {t('chooseDemo.subtitle', 'Select a module')}
        </p>
      </div>

      <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row gap-6 justify-center items-center">
        {/* Supplier Card */}
        <div className="choose-demo-card bg-white shadow-md rounded-lg p-6 flex flex-col items-start w-full md:w-1/2 lg:w-1/3 hover:shadow-lg transition-shadow">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`}
            alt={t('chooseDemo.supplierIconAlt', 'Supplier Icon')}
            className="mb-4"
          />
          <div className="flex flex-col items-start">
            <p className="text-gray-500 text-xs uppercase tracking-wide mb-2">
              {t('chooseDemo.caseScenario', 'Case Scenario')}
            </p>
            <h2 className="text-lg font-semibold text-gray-800 leading-snug">
              {t('chooseDemo.supplierDemo', 'I want to demo')}
              <br />
              {t('chooseDemo.asSupplier', 'as a Supplier.')}
            </h2>
          </div>
        </div>

        {/* Operator Card */}
        <div className="choose-demo-card bg-white shadow-md rounded-lg p-6 flex flex-col items-start w-full md:w-1/2 lg:w-1/3 hover:shadow-lg transition-shadow">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/chooseDemo/operator-icon.svg`}
            alt={t('chooseDemo.operatorIconAlt', 'Operator Icon')}
            className="mb-4"
          />
          <div className="flex flex-col items-start">
            <p className="text-gray-500 text-xs uppercase tracking-wide mb-2">
              {t('chooseDemo.caseScenario', 'Case Scenario')}
            </p>
            <h2 className="text-lg font-semibold text-gray-800 leading-snug">
              {t('chooseDemo.operatorDemo', 'I want to demo')}
              <br />
              {t('chooseDemo.asOperator', 'as an Operator.')}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseDemo;
