import axios, {
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { Endpoint } from './endpoint/endpoint';
import { setGlobalLoadingState } from './core/context/GlobalContext';
import { RefreshToken } from './services/AccountServices';
import { toast } from 'react-toastify';

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// List of endpoints to exclude from adding the token
const excludedEndpoints: string[] = [
  Endpoint.account.login,
  Endpoint.account.register,
  Endpoint.account.googleAuth,
  Endpoint.account.confirmEmail,
  Endpoint.account.resendOtp,
  Endpoint.account.forgotPassword,
  Endpoint.account.resetPassword,
];

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    // Set loading to true when a request is made
    setGlobalLoadingState(true);

    if (
      config.url &&
      !excludedEndpoints.some((url) => config.url?.includes(url))
    ) {
      const token = localStorage.getItem('accessToken');
      if (token) {
        // Add the Authorization header if it exists
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        } as AxiosRequestHeaders;
      }
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    // Set loading to false if there was an error before the request is sent
    setGlobalLoadingState(false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // Set loading to false when the response is received
    setGlobalLoadingState(false);
    return response;
  },
  async (error: AxiosError): Promise<AxiosError> => {
    // Set loading to false when the response has an error
    setGlobalLoadingState(false);

    if (error.response && error.response.status === 401) {
      // If token is invalid or expired, redirect to login
      window.location.href = '/login';
    }
    if (error.response && error.response.status === 404) {
      // If token is invalid or expired, refresh token
      const refreshToken = localStorage.getItem('refreshToken');
      const accessToken = localStorage.getItem('accessToken');

      if (refreshToken && accessToken) {
        try {
          const response = await RefreshToken({ refreshToken, accessToken });
          if (response) {
            localStorage.setItem('accessToken', response.accessToken);
            localStorage.setItem('refreshToken', response.refreshToken);
          }
        } catch (error: any) {
          toast.error(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
